import React from 'react';
import './Services.css';

const Services = ({ merchant }) => {
  const stringServices = merchant.extended_data.extended_string_data_27;
  let services = [];
  if (stringServices) {
    services = stringServices.split('-');
  }
  return (
    <>
      {services.length > 0 && (
        <section className="servicesWrapper">
          <h4>SERVICES</h4>
          <ul>
            {services.map((service) => {
              return <li>{service.toUpperCase()}</li>;
            })}
          </ul>
        </section>
      )}
    </>
  );
};

export default Services;
