import React, { useEffect, useRef } from "react";
import "./Footer.css";

const Footer = () => {
  const navRef = useRef()

  useEffect(() => {
    if (navRef.current) {
      navRef.current.style.display = "flex"
      navRef.current.style.flexDirection = "column"
    }
  }, [])

  return (
    <div className="Footer">
      <footer className="footer">
        <div className="wrap footer__flex">
          <a className="footer__logo" href="https://vog.fr/" title="Accueil">
            <img
              src="https://vog.fr/Content/images/logo-vog-blanc.png"
              alt=""
            />
          </a>

          {/* <div className="footer__newsletter" id="footer-newsletter">
            <div className="alt-title">Abonnez-vous à la newsletter</div>
            <div className="find-box__city">
              <input
                id="newsletter-email"
                name="CustomerEmail"
                placeholder="Saisissez votre adresse email"
                type="email"
                value=""
              />
              <span
                className="field-validation-valid"
                data-valmsg-for="CustomerEmail"
                data-valmsg-replace="true"
              ></span>
              <button
                className="btn"
                type="button"
                onclick="SubscribeNewsletter('/subscribenewsletter', 'Entrez un email valide')"
              >
                OK
              </button>
            </div>
          </div> */}
          <nav className="footer__nav" ref={navRef}>
            <div>
              <ul className="searchShortcutsCustomListWrapper">
                <li>
                  {/* eslint-disable-next-line */}
                  <a href="#">Notre marque</a>
                  <ul>
                    <li>
                      <a href="https://vog.fr/marquehistoirevog-2">
                        Notre histoire
                      </a>
                    </li>
                    <li>
                      <a href="https://vog.fr/engagementsexpertisevog">
                        Nos engagements
                      </a>
                    </li>
                    <li>
                      <a href="https://vog.fr/salonexpertisevog">
                        L&#39;exp&#233;rience salon
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/">Nos salons</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a href="#">Notre expertise</a>
                  <ul>
                    <li>
                      <a href="https://vog.fr/colorexpertisevog">
                        Notre expertise couleur
                      </a>
                    </li>
                    <li>
                      <a href="https://vog.fr/soinexpertisevog">
                        Notre expertise soin
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="https://vog.fr/nos_collections">Nos collections</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a href="#">Nos conseils</a>
                  <ul>
                    <li>
                      <a href="https://vog.fr/nos-conseils-cheveux">
                        Nos conseils cheveux
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="https://vog.fr/our_products">Nos produits</a>
                </li>
                <li>
                  <a href="https://vog.fr/nous-rejoindre" title="Rejoignez-nous">
                    Rejoignez-nous
                  </a>
                </li>
                <li>
                  <a
                    className="critizr-widget"
                    href="https://critizr.com/widgets/vog-vog/"
                    data-type="store_button:fact_national"
                    data-params='{"on_click":{"type":"cz:open:store_locator","params":{"hidden":"true"}},"cta_text":"nous contacter"}'
                  >
                    Critizr: Envoyer mon avis
                  </a>
                </li>
                <li>
                  <a
                    href="https://vog.fr/mentionslegalesvog"
                    title="Mentions légales"
                  >
                    Mentions légales
                  </a>
                </li>
                <li>
                  <a href="https://vog.fr/Salon/FindSalonByDep" title="Annuaire">
                    Annuaire
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div className="footer__social">
            <div className="alt-title">Suivez-nous</div>
            {/* eslint-disable-next-line */}
            <a
              className="icon-facebook"
              href="https://www.facebook.com/"
              target="_blank"
              rel="external noopener noreferrer"
              title="Facebook"
            ></a>
            {/* eslint-disable-next-line */}
            <a
              className="icon-youtube"
              href="https://www.youtube.com/"
              target="_blank"
              rel="external noopener noreferrer"
              title="YouTube"
            ></a>
            {/* eslint-disable-next-line */}
            <a
              className="icon-instagram"
              href="https://instagram.com/"
              target="_blank"
              rel="external noopener noreferrer"
              title="Instagram"
            ></a>
          </div>

          <div className="footer__rights">
            &copy;2022 VOG. Tous droits réservés.
            <br className="hide-md" />
            <a
              href="https://vog.fr/privacyinfovog"
              title="Politique de Confidentialité"
            >
              Politique de Confidentialité.
            </a>
            <a
              href="https://vog.fr/cookiedisclaimervog"
              title="Politique de Cookie"
            >
              Politique de Cookie.
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
