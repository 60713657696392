import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="Header">
      <header className="header headRoom">
        <div className="header__flex">
          <button className="burger toggleDrawer">
            <span></span>
          </button>
          <h1 className="header__logo">
            <a href="https://vog.fr/">
              <img
                src="https://vog.fr/Content/images/logo-vog.png"
                alt="Vog Coiffure"
              />
            </a>
          </h1>
          <a
            href="https://vog.fr/login"
            className="header__user"
            title="Mon compte"
          >
            Mon compte
          </a>
        </div>
        <div className="drawer">
          <div className="top-header">
            <div className="top-header__flex wrap wrap--only-desk">
              <div className="social-links">
                {/* eslint-disable-next-line */}
                <a
                  className="icon-facebook"
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="Facebook"
                ></a>
                {/* eslint-disable-next-line */}
                <a
                  className="icon-youtube"
                  href="https://www.youtube.com/"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="YouTube"
                ></a>
                {/* eslint-disable-next-line */}
                <a
                  className="icon-instagram"
                  href="https://instagram.com/"
                  target="_blank"
                  rel="external noopener noreferrer"
                  title="Instagram"
                ></a>
              </div>
              <div className="quick-links">
                {/* <a
                  href="#footer-newsletter"
                  className="icon-email"
                  title="inscription à la newsletter"
                >
                  Newsletter
                </a>*/}
                <a
                  href="https://vog.fr/login"
                  className="icon-user"
                  title="accéder à mon compte en ligne"
                >
                  Vog &amp; Moi
                </a>
                {/* <script type="text/javascript">
                            $('.icon-user').click(function (e)
                            {
                                e.preventDefault();

                                if ($('.popup--identification').length == 1 && !$('.popup--identification').hasClass('active'))
                                    $('.popup--identification').addClass('active');
                            });
                                    
                        </script> */}
              </div>
            </div>
          </div>

          <nav
            className="main-nav"
            role="navigation"
            arialabel="Main navigation"
          >
            <ul>
              <li className="prune hasSubnav">
                <span>Notre marque</span>
                <ul
                  className="subnav"
                  style={{
                    display: 'none',
                    paddingTop: '0px',
                    marginTop: '0px',
                    paddingBottom: '16px',
                    marginBottom: '0px',
                  }}
                >
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/marquehistoirevog-2"
                      title="Notre histoire"
                    >
                      Notre histoire
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/engagementsexpertisevog"
                      title="Nos engagements"
                    >
                      Nos engagements
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/salonexpertisevog"
                      title="L&#39;exp&#233;rience salon"
                    >
                      L&#39;exp&#233;rience salon
                    </a>
                  </li>
                </ul>
              </li>
              <li className="or">
                <a href="/">Nos salons</a>
              </li>
              <li className="prune hasSubnav">
                <span>Notre expertise</span>
                <ul
                  className="subnav"
                  style={{
                    display: 'none',
                    paddingTop: '0px',
                    marginTop: '0px',
                    paddingBottom: '16px',
                    marginBottom: '0px',
                  }}
                >
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/colorexpertisevog"
                      title="Notre expertise couleur"
                    >
                      Notre expertise couleur
                    </a>
                  </li>
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/soinexpertisevog"
                      title="Notre expertise soin"
                    >
                      Notre expertise soin
                    </a>
                  </li>
                </ul>
              </li>
              <li className="or">
                <a href="https://vog.fr/nos_collections">Nos collections</a>
              </li>
              <li className="prune hasSubnav">
                <span>Nos conseils</span>
                <ul
                  className="subnav"
                  style={{
                    display: 'none',
                    paddingTop: '0px',
                    marginTop: '0px',
                    paddingBottom: '16px',
                    marginBottom: '0px',
                  }}
                >
                  <li className="subnav__item">
                    <a
                      href="https://vog.fr/nos-conseils-cheveux"
                      title="Nos conseils cheveux"
                    >
                      Nos conseils cheveux
                    </a>
                  </li>
                </ul>
              </li>
              <li className="or">
                <a href="https://vog.fr/our_products">Nos produits</a>
              </li>
              <li className="bg-gold">
                <a href="/">
                  PRENDRE RENDEZ-VOUS
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
