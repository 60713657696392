import { useEffect as t } from "react";
var n = function (n, e, r) {
  t(function () {
    var t = function (t) {
      var o;
      !n.current || n.current.contains(t.target) || null != r && null !== (o = r.current) && void 0 !== o && o.contains(t.target) || e(t);
    };
    return document.addEventListener("mousedown", t), document.addEventListener("touchstart", t), function () {
      document.removeEventListener("mousedown", t), document.removeEventListener("touchstart", t);
    };
  }, [n, e]);
};
export { n as default };