import { _ as e } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import { useState as n, useEffect as r } from "react";
var t = function (t) {
  var o = n(!1),
    i = e(o, 2),
    u = i[0],
    a = i[1];
  function d(e) {
    e.key === t && a(!0);
  }
  var w = function (e) {
    e.key === t && a(!1);
  };
  return r(function () {
    return window.addEventListener("keydown", d), window.addEventListener("keyup", w), function () {
      window.removeEventListener("keydown", d), window.removeEventListener("keyup", w);
    };
  }, []), u;
};
export { t as default };