import { c as e, d as t, b as n } from "../_rollupPluginBabelHelpers-f0c52a37.js";
var a = new (function () {
  function a() {
    t(this, a), n(this, "daysPositon", {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 0
    }), n(this, "todayHour", new Date().getHours().toString()), n(this, "todayMinutes", new Date().getMinutes().toString()), n(this, "today", new Date().getDay()), n(this, "todayMonth", new Date().getMonth()), n(this, "todayYear", new Date().getMinutes()), n(this, "todayJS", new Date()), n(this, "frenchWeekdays", {
      monday: {
        name: "Lundi",
        position: 1
      },
      tuesday: {
        name: "Mardi",
        position: 2
      },
      wednesday: {
        name: "Mercredi",
        position: 3
      },
      thursday: {
        name: "Jeudi",
        position: 4
      },
      friday: {
        name: "Vendredi",
        position: 5
      },
      saturday: {
        name: "Samedi",
        position: 6
      },
      sunday: {
        name: "Dimanche",
        position: 7
      }
    });
  }
  return e(a, [{
    key: "_deepClone",
    value: function (e) {
      if (e) return JSON.parse(JSON.stringify(e));
    }
  }, {
    key: "_getActualWorkingDay",
    value: function () {
      var e = "";
      for (var t in this.daysPositon) {
        if (this.daysPositon[t] === this.today) {
          e = t;
          break;
        }
      }
      return e;
    }
  }, {
    key: "_hourToInt",
    value: function (e) {
      var t = e.split(":");
      if (t) return parseInt(t.join(""));
    }
  }, {
    key: "_placeloopLikeHourFormat",
    value: function (e, t) {
      return (e < 10 ? "0" + e : e) + ":" + (t < 10 ? "0" + t : t);
    }
  }, {
    key: "storeIsOpen",
    value: function (e) {
      var t = this.regularOpeningHoursIsOpen(e),
        n = this.additionalDaysIsOpen(e);
      return t || n;
    }
  }, {
    key: "additionalDaysIsOpen",
    value: function () {
      var e = this,
        t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        n = t.additionalDays,
        a = void 0 === n ? [] : n,
        r = a.map(function (t) {
          var n = new Date();
          if (new Date(t.day.date).toDateString() === n.toDateString()) return t.hours.map(function (t) {
            return e.isInRange(t);
          }).includes(!0);
        });
      return r.includes(!0);
    }
  }, {
    key: "regularOpeningHoursIsOpen",
    value: function (e) {
      var t = this,
        n = this._getActualWorkingDay(),
        a = this._deepClone(e.workingDaysPerWeek[n]);
      return 0 !== a.length && a.map(function (e) {
        return t.isInRange(e);
      }).includes(!0);
    }
  }, {
    key: "isInRange",
    value: function (e) {
      var t = this._hourToInt(e.begin),
        n = this._hourToInt(e.end),
        a = this._placeloopLikeHourFormat(this.todayHour, this.todayMinutes),
        r = this._hourToInt(a);
      return r > t && r < n;
    }
  }, {
    key: "formatHour",
    value: function (e) {
      var t = e.replace(":", "h");
      return -1 !== t.indexOf("00", t.length - 2) ? t.substring(0, t.length - 2) : t;
    }
  }, {
    key: "getActualDayToString",
    value: function () {
      var e = new Date().getDay();
      return 0 === e && (e = 7), Object.values(this.frenchWeekdays).find(function (t) {
        return t.position === e;
      }).name;
    }
  }, {
    key: "getActualDayToStringKey",
    value: function () {
      var e = new Date().getDay(),
        t = "";
      for (var n in this.daysPositon) {
        this.daysPositon[n] === e && (t = n);
      }
      return t;
    }
  }, {
    key: "getCleanWorkingDays",
    value: function (e) {
      var t = this,
        n = {};
      for (var a in e) {
        var r = e[a];
        n[this.frenchWeekdays[a].name] = this._deepClone(r);
      }
      for (var i in n) {
        var o = n[i];
        o.length > 0 && (n[i] = o.map(function (e) {
          return e.begin = t.formatHour(e.begin), e.end = t.formatHour(e.end), e;
        }));
      }
      return n;
    }
  }, {
    key: "setAge",
    value: function (e) {
      var t = e.day,
        n = e.month,
        a = e.year,
        r = new Date(a, parseInt(n) - 1, t),
        i = Date.now() - r.getTime(),
        o = new Date(i);
      return Math.abs(o.getUTCFullYear() - 1970);
    }
  }, {
    key: "getDateFromStrings",
    value: function (e) {
      var t = e.day,
        n = e.month,
        a = e.year,
        r = {
          day: 0,
          month: 0,
          year: 0
        };
      return r.day = parseInt(t), r.month = parseInt(n), r.year = parseInt(a), r;
    }
  }, {
    key: "displayOpeningStatus",
    value: function (e) {
      var t = [];
      for (var n in e) {
        var a = 0 !== e[n].length;
        t.push(!!a);
      }
      return t.includes(!0);
    }
  }, {
    key: "filterAdditionalDay",
    value: function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 30,
        n = e.day.date,
        a = new Date();
      a.setHours(0, 0, 0, 0);
      var r = new Date(n);
      if (r < a) return !1;
      var i = new Date();
      return i.setDate(i.getDate() + t), !(r > i);
    }
  }, {
    key: "getTodayAdditionalDay",
    value: function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
      return e.find(function (e) {
        var t = new Date(),
          n = new Date(e.day.date);
        if (console.log(n, t), n.toDateString() === t.toDateString()) return !0;
      });
    }
  }, {
    key: "isAfterFromIso",
    value: function (e, t) {
      return new Date(t) > new Date(e);
    }
  }, {
    key: "isBeforeFromIso",
    value: function (e, t) {
      return new Date(t) < new Date(e);
    }
  }, {
    key: "toFormatFromIso",
    value: function (e, t) {
      var n = new Date(e),
        a = n.getFullYear(),
        r = n.getMonth() + 1,
        i = n.getDate(),
        o = n.getHours(),
        u = n.getMinutes(),
        s = n.getSeconds();
      return t.replace(/yyyy/g, a).replace(/MM/g, r.toString().padStart(2, "0")).replace(/dd/g, i.toString().padStart(2, "0")).replace(/HH/g, o.toString().padStart(2, "0")).replace(/mm/g, u.toString().padStart(2, "0")).replace(/ss/g, s.toString().padStart(2, "0"));
    }
  }]), a;
}())();
export { a as default };