import { c as t, d as r, b as e } from "../_rollupPluginBabelHelpers-f0c52a37.js";
var n = new (function () {
  function n() {
    r(this, n), e(this, "rootURL", ["http://", "https://", "https//"]);
  }
  return t(n, [{
    key: "cleanHTTP",
    value: function (t) {
      if (!t) return "";
      var r = t;
      return this.rootURL.forEach(function (t) {
        var e = new RegExp(t, "g");
        r = r.replace(e, "");
      }), r;
    }
  }, {
    key: "modifyHTTP",
    value: function (t) {
      var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "https://";
      if (!t) return "";
      var e = this.cleanHTTP(t),
        n = r + e;
      return n;
    }
  }]), n;
}())();
export { n as default };