import e from "react";
var t = function (t) {
  var r,
    l,
    a,
    s = t.section,
    o = t.style,
    n = void 0 === o ? {} : o,
    p = t.classes,
    i = void 0 === p ? {} : p,
    c = {
      wrapper: n.wrapper || {},
      title: n.title || {},
      content: n.content || {},
      separators: {
        top: (null == n || null === (r = n.separators) || void 0 === r ? void 0 : r.top) || !1,
        bottom: (null == n || null === (l = n.separators) || void 0 === l ? void 0 : l.bottom) || !1,
        style: (null == n || null === (a = n.separators) || void 0 === a ? void 0 : a.style) || {}
      }
    },
    m = {
      wrapper: i.wrapper || "",
      title: i.title || "",
      content: i.content || ""
    };
  return e.createElement(e.Fragment, null, c.separators.top && e.createElement("hr", {
    style: c.separators.style
  }), s.fields.map(function (t, r) {
    return e.createElement("article", {
      key: r,
      style: c.wrapper,
      className: m.wrapper
    }, s.title && e.createElement("h2", {
      style: c.title,
      className: m.title
    }, s.title), e.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: t.desc
      },
      style: c.content,
      className: m.content
    }));
  }), c.separators.bottom && e.createElement("hr", {
    style: c.separators.style
  }));
};
export { t as default };