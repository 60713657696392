import r, { useEffect as n, createRef as e } from "react";
import { s as o } from "../../../style-inject.es-1f59c1d0.js";
o(".loader {\n  width: 48px;\n  height: 48px;\n  border: 5px solid #fff;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite; }\n  .loader.small {\n    width: 20px;\n    height: 20px;\n    border-width: 2px; }\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n");
var t = function (o) {
  var t = o.size,
    a = void 0 === t ? "" : t;
  o.color;
  var i = e();
  return n(function () {
    i.current;
  }), r.createElement("span", {
    className: "loader " + a,
    ref: i
  });
};
export { t as default };