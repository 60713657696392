import { c as i, d as e, b as t } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
var n = new (function () {
  function n() {
    e(this, n), t(this, "script", {
      _trigger: function () {
        console.log("facebookPixel is loading");
      },
      _key: "",
      _inited: !1,
      _cookies: {
        _fbc: "",
        _fbp: ""
      }
    });
  }
  return i(n, [{
    key: "trigger",
    set: function (i) {
      this.script._trigger = i;
    }
  }, {
    key: "key",
    get: function () {
      return this.script._key;
    },
    set: function (i) {
      this.script._key = i;
    }
  }, {
    key: "inited",
    get: function () {
      return this.script._inited;
    },
    set: function (i) {
      this.script._inited = i;
    }
  }, {
    key: "cookies",
    get: function () {
      return this.script._cookies;
    },
    set: function (i) {
      for (var e in i) {
        var t = i[e];
        t && (this.script._cookies[e] = t);
      }
    }
  }, {
    key: "getfbclid",
    value: function () {
      var i = window.location.search;
      if (i) {
        var e = i.substring(1),
          t = new URLSearchParams(e).get("fbclid");
        return encodeURIComponent(t);
      }
      return "";
    }
  }, {
    key: "getFacebookPixelInfos",
    value: function () {
      return {
        fbclid: this.getfbclid(),
        fbp: this.cookies._fbp,
        fbc: this.cookies._fbc
      };
    }
  }, {
    key: "pageView",
    value: function (i) {
      var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
      this.script._trigger("track", i, {}, {
        eventID: e
      });
    }
  }, {
    key: "trackEvent",
    value: function () {
      var i = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
        n = t.trackingEventName,
        o = t.eventID,
        r = void 0 === o ? "" : o,
        c = t.source,
        s = void 0 === c ? "SL" : c,
        u = (null == e ? void 0 : e.id) || (null == e ? void 0 : e.name) || (null == e ? void 0 : e.id) || "",
        a = (null == i ? void 0 : i.name) || (null == i ? void 0 : i.id) || "",
        l = {
          EventSource: s
        };
      u && (l.sponsor = u), a && (l.place_id = a), this.script._trigger("track", n, l, {
        eventID: r
      });
    }
  }]), n;
}())();
export { n as default };