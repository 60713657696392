import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as r, b as t, e as n } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import o from "../ApiRequests.js";
import s from "./FacebookPixel.js";
import "axios";
var i = new (function () {
  function i() {
    r(this, i), t(this, "script", {
      _inited: !0
    });
  }
  var a, c, u, p, l, d, f;
  return e(i, [{
    key: "inited",
    get: function () {
      return this.script._inited;
    }
  }, {
    key: "pageView",
    value: (f = n(_regeneratorRuntime.mark(function e(r, t, n) {
      var i,
        a,
        c,
        u,
        p,
        l,
        d = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return i = d.length > 3 && void 0 !== d[3] ? d[3] : "", a = s.getFacebookPixelInfos(), c = a.fbclid, u = a.fbp, p = {
              storelocator: {
                eventID: i,
                fbclid: c,
                fbp: u,
                segmentId: r.segmentId,
                shortenerId: plshortener.shortenerId || "",
                shortenerSource: plshortener.shortenerSource || "",
                userAgent: plshortener.userAgent || "",
                ipAddress: plshortener.ipAddress || ""
              },
              localpage: {
                eventID: i,
                fbclid: c,
                fbp: u,
                sponsor: r.id,
                place_id: t,
                event_kind: "pageview",
                segmentId: r.segmentId,
                shortenerId: plshortener.shortenerId || "",
                shortenerSource: plshortener.shortenerSource || "",
                userAgent: plshortener.userAgent || "",
                ipAddress: plshortener.ipAddress || ""
              }
            }, l = "storelocator" === n ? "pageview" : "", e.next = 6, o.PlTracking(p[n], r.id, l);
          case 6:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e, r, t) {
      return f.apply(this, arguments);
    })
  }, {
    key: "trackSearchedPlace",
    value: (d = n(_regeneratorRuntime.mark(function e(r, t, n) {
      var i,
        a,
        c,
        u,
        p,
        l = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return i = l.length > 3 && void 0 !== l[3] ? l[3] : "", a = s.getFacebookPixelInfos(), c = a.fbclid, u = a.fbp, p = {
              fbclid: c,
              fbp: u,
              name: t,
              term: t,
              address: n,
              eventID: i
            }, e.next = 5, o.PlTracking(p, r.id, "search");
          case 5:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e, r, t) {
      return d.apply(this, arguments);
    })
  }, {
    key: "trackClickToCall",
    value: (l = n(_regeneratorRuntime.mark(function e(r, t, n) {
      var o,
        s = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return o = s.length > 3 && void 0 !== s[3] ? s[3] : "", e.next = 3, this._trackEvent(r, t, n, "phonecall", o);
          case 3:
            return e.abrupt("return", e.sent);
          case 4:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, r, t) {
      return l.apply(this, arguments);
    })
  }, {
    key: "trackClickToDirection",
    value: (p = n(_regeneratorRuntime.mark(function e(r, t, n) {
      var o,
        s = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return o = s.length > 3 && void 0 !== s[3] ? s[3] : "", e.next = 3, this._trackEvent(r, t, n, "direction", o);
          case 3:
            return e.abrupt("return", e.sent);
          case 4:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, r, t) {
      return p.apply(this, arguments);
    })
  }, {
    key: "trackLPEvent",
    value: (u = n(_regeneratorRuntime.mark(function e(r, t, n, o) {
      var s,
        i = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return s = i.length > 4 && void 0 !== i[4] ? i[4] : "", e.next = 3, this._trackEvent(r, t, n, o, s);
          case 3:
            return e.abrupt("return", e.sent);
          case 4:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, r, t, n) {
      return u.apply(this, arguments);
    })
  }, {
    key: "_trackEvent",
    value: (c = n(_regeneratorRuntime.mark(function e(r, t, n, i) {
      var a,
        c,
        u,
        p,
        l,
        d = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return a = d.length > 4 && void 0 !== d[4] ? d[4] : "", c = s.getFacebookPixelInfos(), u = c.fbclid, p = c.fbp, l = {
              eventID: a,
              fbclid: u,
              fbp: p,
              sponsor: r.id,
              place_id: t,
              place_kind: n,
              event_kind: i
            }, e.next = 5, o.PlTracking(l);
          case 5:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e, r, t, n) {
      return c.apply(this, arguments);
    })
  }, {
    key: "trackGeolocatedPlace",
    value: (a = n(_regeneratorRuntime.mark(function e(r, t, n, i) {
      var a,
        c,
        u,
        p,
        l,
        d = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return a = d.length > 4 && void 0 !== d[4] ? d[4] : "", c = s.getFacebookPixelInfos(), u = c.fbclid, p = c.fbp, l = {
              eventID: a,
              fbclid: u,
              fbp: p,
              lat: t,
              lng: n,
              reverse: i,
              shortenerId: plshortener.shortenerId || "",
              shortenerSource: plshortener.shortenerSource || "",
              userAgent: plshortener.userAgent || "",
              ipAddress: plshortener.ipAddress || ""
            }, e.next = 5, o.PlTracking(l, r.id, "geolocation");
          case 5:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e, r, t, n) {
      return a.apply(this, arguments);
    })
  }]), i;
}())();
export { i as default };