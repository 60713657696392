import { c as e, d as t } from "../_rollupPluginBabelHelpers-f0c52a37.js";
var a = new (function () {
  function a() {
    t(this, a);
  }
  return e(a, [{
    key: "extractNumber",
    value: function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
        a = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
        r = a ? parseFloat(e) : parseInt(e);
      return isNaN(r) ? this.extractNumber(t) : r;
    }
  }, {
    key: "isBoolean",
    value: function (e) {
      return !0 === e || !1 === e;
    }
  }, {
    key: "extractString",
    value: function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "",
        a = "string" == typeof e ? e.trim() : this.extractString(t);
      return a;
    }
  }, {
    key: "extractBoolean",
    value: function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
        a = this.extractString(e).toLocaleLowerCase();
      switch (a) {
        case "true":
        case "yes":
        case "on":
        case "oui":
        case "si":
          return !0;
        case "false":
        case "no":
        case "off":
        case "non":
          return !1;
        default:
          return this.isBoolean(e) ? e : this.extractBoolean(t);
      }
    }
  }]), a;
}())();
export { a as default };