import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as r, e as t } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import n from "./Tracking/Tracking.js";
import a from "./ApiRequests.js";
import "./Tracking/FacebookPixel.js";
import "./Tracking/Pl.js";
import "axios";
import "./Tracking/GoogleAnalitycs.js";
import "./Utils.js";
import "./Tracking/GoogleTagManager.js";
var o = function () {
  function o(e) {
    r(this, o), this.mapService = e;
  }
  var i, c, s, u, p;
  return e(o, [{
    key: "getPlacePredictions",
    value: (p = t(_regeneratorRuntime.mark(function e() {
      var r,
        t,
        n = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return r = n.length > 0 && void 0 !== n[0] ? n[0] : "", e.next = 3, a.geoapify.getPlacePredictions(r);
          case 3:
            return t = e.sent, e.abrupt("return", t);
          case 5:
          case "end":
            return e.stop();
        }
      }, e);
    })), function () {
      return p.apply(this, arguments);
    })
  }, {
    key: "getReverseGeocoding",
    value: (u = t(_regeneratorRuntime.mark(function e(r, t) {
      var n;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return e.next = 2, a.geoapify.reverseGeocoding(r, t);
          case 2:
            return n = e.sent, e.abrupt("return", n);
          case 4:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e, r) {
      return u.apply(this, arguments);
    })
  }, {
    key: "getPlaceByName",
    value: (s = t(_regeneratorRuntime.mark(function e(r) {
      var t;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return e.next = 2, a.geoapify.getPlaceByName(r);
          case 2:
            return t = e.sent, e.abrupt("return", t[0]);
          case 4:
          case "end":
            return e.stop();
        }
      }, e);
    })), function (e) {
      return s.apply(this, arguments);
    })
  }, {
    key: "goToLocationByPrediction",
    value: (c = t(_regeneratorRuntime.mark(function e(r) {
      var t;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return n.trackSearchedPlace(r.formatted, r.formatted), t = {
              skip: "0",
              number_of: this.mapService.numberOfMarkers,
              order_by: "distance",
              order_dir: "asc",
              from_lat: r.lat,
              from_lng: r.lon
            }, e.abrupt("return", {
              pagination: t
            });
          case 3:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e) {
      return c.apply(this, arguments);
    })
  }, {
    key: "goToLocationByPlaceName",
    value: (i = t(_regeneratorRuntime.mark(function e(r) {
      var t, n;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return e.next = 2, this.getPlaceByName(r);
          case 2:
            return t = e.sent, n = {
              skip: "0",
              number_of: this.mapService.numberOfMarkers,
              order_by: "distance",
              order_dir: "asc",
              from_lat: t.lat,
              from_lng: t.lon
            }, e.abrupt("return", {
              pagination: n,
              placeLoc: {
                lat: t.lat,
                lng: t.lon
              }
            });
          case 5:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e) {
      return i.apply(this, arguments);
    })
  }]), o;
}();
export { o as default };