import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { _ as e, a as r, e as n } from "../../../../_rollupPluginBabelHelpers-f0c52a37.js";
import t, { useContext as o, useState as i, useMemo as a, useEffect as l, createRef as c } from "react";
import { B as s } from "../../../../index.esm-d54eecc2.js";
import { s as p } from "../../../../style-inject.es-1f59c1d0.js";
import u from "../../../../services/Map.js";
import d from "../../../../services/ApiRequests.js";
import f from "../../../../contexts/map-context.js";
import v from "../../../../contexts/state-context.js";
import m from "../../../../customHooks/useDebounce.js";
import g from "../../../../customHooks/useKeyPress.js";
import x from "../../../../customHooks/useOnClickOutside.js";
import "../../../../iconBase-0f0e1f0c.js";
import "../../../../services/GMap.js";
import "axios";
import "../../../../services/Tracking/Tracking.js";
import "../../../../services/Tracking/FacebookPixel.js";
import "../../../../services/Tracking/Pl.js";
import "../../../../services/Tracking/GoogleAnalitycs.js";
import "../../../../services/Utils.js";
import "../../../../services/Tracking/GoogleTagManager.js";
import "../../../../services/Geoapify.js";
p(".gMapSearchWrapper {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative; }\n\n.gMapSearchInputWrapper {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  max-height: 38px; }\n\n.gMapSearchInputWrapper button {\n  background-color: var(--background-color);\n  border: none;\n  color: var(--wrapper-color-dark);\n  font-size: var(--fontsize-xxl);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  cursor: pointer;\n  width: 40px; }\n\n.gMapSearchInputWrapper input {\n  width: 100%;\n  height: 36px;\n  border: var(--wrapper-color);\n  font-size: var(--fontsize-l);\n  padding-left: var(--gutter-m);\n  border-radius: 0;\n  -webkit-border-radius: 0; }\n\n.MapSearchPredictionsWrapper {\n  position: absolute;\n  width: 100%;\n  box-shadow: var(--boxshadow-reg);\n  display: flex;\n  flex-direction: column;\n  background-color: var(--background-color);\n  border-bottom: 1px solid var(--wrapper-color); }\n\n.MapSearchPredictionsWrapper a {\n  padding: var(--gutter-m);\n  color: var(--wrapper-color-dark);\n  border-top: 1px solid var(--wrapper-color);\n  transition: all .3s;\n  font-size: var(--fontsize-m); }\n\n.MapSearchPredictionsWrapper a:hover {\n  color: var(--text-color-inv);\n  background-color: var(--wrapper-color-dark); }\n");
var h = function (p) {
  var h,
    b,
    y,
    w = p.button,
    k = p.input,
    j = p.predictionsSelect,
    S = p.wrapper,
    M = p.onSubmitDone,
    P = p.children,
    _ = {
      wrapper: {
        style: (null == S ? void 0 : S.style) || {}
      },
      input: {
        style: (null == k ? void 0 : k.style) || {},
        label: (null == k ? void 0 : k.label) || "Rechercher une adresse",
        placeholder: (null == k ? void 0 : k.placeholder) || "Ex : Paris, 92230, 39 avenue Montaigne"
      },
      button: {
        style: (null == w ? void 0 : w.style) || {},
        icon: (null == w ? void 0 : w.icon) || t.createElement(s, null),
        iconDisabled: (null == w ? void 0 : w.iconDisabled) || !1,
        value: (null == w ? void 0 : w.value) || ""
      },
      predictionsSelect: {
        wrapper: {
          style: (null == j || null === (h = j.wrapper) || void 0 === h ? void 0 : h.style) || {}
        },
        boxes: {
          hover: (null == j || null === (b = j.boxes) || void 0 === b ? void 0 : b.hover) || "var(--primary-color)",
          style: (null == j || null === (y = j.boxes) || void 0 === y ? void 0 : y.style) || {}
        }
      }
    },
    E = o(f),
    W = e(E, 2),
    R = W[0],
    D = W[1],
    L = o(v),
    T = e(L, 2),
    z = T[0],
    C = T[1],
    I = i([]),
    B = e(I, 2),
    G = B[0],
    H = B[1],
    A = i({
      id: "",
      index: 0
    }),
    N = e(A, 2),
    F = N[0],
    O = N[1],
    U = i(""),
    q = e(U, 2),
    K = q[0],
    J = q[1],
    Q = m(K, 300),
    V = c(),
    X = c(),
    Y = c(),
    Z = g("ArrowUp"),
    $ = g("ArrowDown"),
    ee = i(!1),
    re = e(ee, 2),
    ne = re[0],
    te = re[1],
    oe = i({
      lat: 0,
      lng: 0
    }),
    ie = e(oe, 2),
    ae = ie[0],
    le = ie[1];
  x(X, function () {
    H([]), O({
      id: "",
      index: 0
    });
  }, Y);
  var ce = function (e) {
      if (e.preventDefault(), G.length > 0) {
        var r = G.find(function (e) {
            return e.place_id === F.id;
          }),
          n = r || G[0];
        se(e, n);
      }
      M && "function" == typeof M && M();
    },
    se = function () {
      var e = n(_regeneratorRuntime.mark(function e(n, t) {
        var o, i;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n.preventDefault(), e.next = 3, u.geoapifyService.goToLocationByPrediction(t);
            case 3:
              return o = e.sent, e.next = 6, d.getBrands(o.pagination, z);
            case 6:
              i = e.sent, le({
                lat: t.lat,
                lng: t.lon
              }), C(i), D(r(r({}, R), {}, {
                placeLoc: {
                  lat: t.lat,
                  lng: t.lon
                },
                placeId: void 0
              })), H([]), J(t.formatted), O({
                id: "",
                index: 0
              }), te(!0);
            case 14:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function (r, n) {
        return e.apply(this, arguments);
      };
    }();
  a(function () {
    var e = function () {
      var e = n(_regeneratorRuntime.mark(function e() {
        var r;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, u.geoapifyService.getPlacePredictions(Q);
            case 2:
              r = e.sent, H(r);
            case 4:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function () {
        return e.apply(this, arguments);
      };
    }();
    Q && !ne && e();
  }, [Q]), l(function () {
    var e = function () {
      var e = n(_regeneratorRuntime.mark(function e(r) {
        var n, t, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n = r.lat, t = r.lon, e.next = 3, u.geoapifyService.getReverseGeocoding(n, t);
            case 3:
              o = e.sent, J(o[0].formatted), te(!0);
            case 6:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function (r) {
        return e.apply(this, arguments);
      };
    }();
    if (R.placeLoc.lat && R.placeLoc.lng && R.scriptInited) {
      var r = {
        lat: R.placeLoc.lat,
        lon: R.placeLoc.lng
      };
      ae.lat === r.lat && ae.lng === r.lon || e(r);
    }
  }, [R.placeLoc.lat, R.placeLoc.lng]), l(function () {
    var e = V.current.offsetHeight;
    X.current && (X.current.style.top = e + "px");
  }, [G]), l(function () {
    0 !== G.length && function () {
      if ($) {
        var e = 1 === G.length,
          r = F.index === G.length - 1;
        if (e) return void O({
          id: G[0].place_id,
          index: 0
        });
        if (r) return;
        return F.id ? void O({
          id: G[F.index + 1].place_id,
          index: F.index + 1
        }) : void O({
          id: G[0].place_id,
          index: 0
        });
      }
      if (Z) {
        if (0 === F.index) return;
        O({
          id: G[F.index - 1].place_id,
          index: F.index - 1
        });
      }
    }();
  }, [$, Z]);
  return t.createElement("form", {
    className: "gMapSearchWrapper",
    onSubmit: function (e) {
      return ce(e);
    },
    style: _.wrapper.style
  }, t.createElement("div", {
    className: "gMapSearchInputWrapper",
    ref: V
  }, t.createElement("input", {
    type: "text",
    value: K,
    onChange: function (e) {
      var r = e.target.value;
      J(r), te(!1), r || H([]);
    },
    style: _.input.style,
    placeholder: _.input.placeholder
  }), t.createElement("button", {
    ref: Y,
    type: "submit",
    style: _.button.style,
    disabled: 0 === K.length,
    onClick: ce
  }, !_.button.iconDisabled && _.button.icon, _.button.value)), P ? t.createElement(t.Fragment, null, t.Children.map(P, function (e) {
    return t.cloneElement(e, {
      predictions: G
    });
  })) : t.createElement(t.Fragment, null, G.length > 0 && t.createElement("div", {
    className: "MapSearchPredictionsWrapper",
    style: _.predictionsSelect.wrapper.style,
    ref: X
  }, G.map(function (e) {
    var n = _.predictionsSelect.boxes.style,
      o = {
        backgroundColor: _.predictionsSelect.boxes.hover
      };
    return t.createElement("a", {
      href: "#",
      className: F.id === e.place_id ? "MapSearchPredictionsSelected" : "",
      id: e.place_id,
      style: F.id === e.place_id ? r(r({}, n), o) : r({}, n),
      onClick: function (r) {
        se(r, e);
      },
      key: e.place_id
    }, function (e) {
      return [e.address_line1 || "", e.state || "", e.address_line2 || ""].filter(function (e) {
        return "" !== e;
      }).join(", ");
    }(e));
  }))));
};
export { h as default };