import React, { useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import LocalPage from "./views/LocalPage/LocalPage";
import StoreLocator from "./views/StoreLocator/StoreLocator";

import StateContext from 'common/contexts/state-context';
import MapContext from 'common/contexts/map-context';
import MapService from 'common/services/Map';
import TrackingService from 'common/services/Tracking/Tracking';
import AntiCorruptionService from 'common/services/AntiCorruption';
import FacebookPixel from 'common/components/Tracking/FacebookPIxel/FacebookPixel';

import Header from "./views/LocalPage/widgets/Header/Header";
import Footer from "./views/LocalPage/widgets/Footer/Footer";

const App = ({ props }) => {
  /// CORE OF REACT STORELOC APP DONT REMOVE IT ////
  const marshalledState = AntiCorruptionService.marshallState(props);
  const [state, setState] = useState(marshalledState);
  const [cookies] = useCookies();
  TrackingService.init(state, cookies);
  MapService.initMap(state);
  const [map, setMap] = useState(MapService.initialContext);
  useContext(MapContext);
  useContext(StateContext);
  /////////////////////////////////////////////////

  // CRTIZR //
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `(function (i, s, o, g, r, a, m) {
      (i[r] = i[r]),
        (a = s.createElement(o)),
        (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://static.critizr.com/widgets/5b9fd93371411890afdc8c75a8cff387.js",
      "cz"
    );`;
    document.head.appendChild(script);
  }, []);

  return (
    <div className="appWrapper">
      <StateContext.Provider value={[state, setState]}>
        <MapContext.Provider value={[map, setMap]}>
          <FacebookPixel hardcodedKey={''} />
          <Header></Header>
          <Switch>
            <Route exact path={['/', '/location/:slug']}>
              <StoreLocator />
            </Route>
            <Route path="/:storeId">
              <LocalPage />
            </Route>
          </Switch>
          <Footer></Footer>
        </MapContext.Provider>
      </StateContext.Provider>
    </div>
  );
};

export default App;
