import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './LocalPage.css';

import TrackingService from 'common/services/Tracking/Tracking';
import StateContext from 'common/contexts/state-context';
import Map from 'common/components/Map/Map';
import RichFieldGroup from 'common/components/RichFieldGroup/RichFieldGroup';

import PlaceInfos from './widgets/PlaceInfos/PlaceInfos';
import OpeningHours from './widgets/OpeningHours/OpeningHours';
import SimpleCard from './widgets/SimpleCard/SimpleCard';
import Services from './widgets/Services/Services';
import NearbyPlacesWrapper from './widgets/NearbyPlacesWrapper/NearbyPlacesWrapper';
import {
  RICHFIELDS_CONFIG_DESKTOP,
  RICHFIELDS_CONFIG_MOBILE,
} from './richfieldsConfig/richfieldsConfig';

const LocalPage = () => {
  const [state] = useContext(StateContext);
  const { storeId } = useParams();

  useEffect(() => {
    TrackingService.pageView('localpage', storeId);
  }, [storeId]);

  const merchantDescription =
    state.merchant.description ||
    state.merchant.extended_data.extended_string_data_7;

  return (
    <>
      <Map mapSize={{ height: '0', width: '0' }} />
      {state.lp && (
        <>
          <header className="localPageHeader"></header>
          <div className="localPageWrapper">
            <div className="localPageCenter">
              <main className="topInfosWrapper">
                <PlaceInfos merchant={state.merchant}></PlaceInfos>
                <OpeningHours merchant={state.merchant}></OpeningHours>
              </main>

              <div className="nearbyPlacesWrapperMobile">
                <NearbyPlacesWrapper></NearbyPlacesWrapper>
              </div>
              <div className="localPageAppAndPricesMobile">
                <SimpleCard
                  merchant={state.merchant}
                  icon={'faCalendarDays'}
                  mergeField={3}
                >
                  <Appointment />
                </SimpleCard>
                <SimpleCard
                  merchant={state.merchant}
                  icon={'faScissors'}
                  mergeField={10}
                >
                  <Prices />
                </SimpleCard>
              </div>

              {merchantDescription && (
                <article className="descriptionWrapper">
                  <h2 className="descriptionTitle">
                    SALON DE COIFFURE {state.merchant.name}
                  </h2>
                  <p className="descriptionContent">{merchantDescription}</p>
                </article>
              )}

              <RichFieldGroup
                groupId={'ASIDE_B'}
                style={RICHFIELDS_CONFIG_MOBILE.ASIDE_B}
                classes={RICHFIELDS_CONFIG_MOBILE.ASIDE_B_CLASSES}
              ></RichFieldGroup>
              <div
                className="localPageAppAndPricesMobile"
                style={{
                  marginTop: 'calc(var(--gutter-l) * -1)',
                }}
              >
                <SimpleCard
                  merchant={state.merchant}
                  mergeField={9}
                  isMail={true}
                >
                  <Join />
                </SimpleCard>

                <div className="localPageHardCodedImage">
                  <img
                    src="https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/88063089620895--orig-preview"
                    alt="vog shag"
                  ></img>
                </div>
              </div>

              <div className="localPageAppAndPricesMobile">
                <RichFieldGroup
                  groupId={'ASIDE_A'}
                  style={RICHFIELDS_CONFIG_MOBILE.ASIDE_A}
                ></RichFieldGroup>
                <Services merchant={state.merchant}></Services>
              </div>

              <RichFieldGroup
                groupId={'MAIN'}
                style={RICHFIELDS_CONFIG_MOBILE.MAIN_B}
                classes={RICHFIELDS_CONFIG_MOBILE.MAIN_B_CLASSES}
              />

              <RichFieldGroup
                groupId={'MAIN_C'}
                style={RICHFIELDS_CONFIG_MOBILE.MAIN_C}
                classes={RICHFIELDS_CONFIG_MOBILE.MAIN_C_CLASSES}
              />
              <RichFieldGroup
                groupId={'MAIN_B'}
                style={RICHFIELDS_CONFIG_MOBILE.MAIN_B}
                classes={RICHFIELDS_CONFIG_MOBILE.MAIN_B_CLASSES}
              />

              <RichFieldGroup
                groupId={'MAIN_B'}
                style={RICHFIELDS_CONFIG_DESKTOP.MAIN_B}
                classes={RICHFIELDS_CONFIG_DESKTOP.MAIN_B_CLASSES}
              />
              <RichFieldGroup
                groupId={'MAIN_C'}
                style={RICHFIELDS_CONFIG_DESKTOP.MAIN_C}
                classes={RICHFIELDS_CONFIG_DESKTOP.MAIN_B_CLASSES}
              />
            </div>
            <div className="desktop-only">
              <Aside state={state} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Aside = ({ state }) => {
  return (
    <aside>
      <SimpleCard
        merchant={state.merchant}
        icon={'faCalendarDays'}
        mergeField={3}
      >
        <Appointment />
      </SimpleCard>
      <SimpleCard merchant={state.merchant} mergeField={10} color={'black'}>
        <Prices />
      </SimpleCard>
      <RichFieldGroup
        groupId={'ASIDE_A'}
        style={RICHFIELDS_CONFIG_DESKTOP.ASIDE_A}
      ></RichFieldGroup>
      <Services merchant={state.merchant}></Services>
      <NearbyPlacesWrapper></NearbyPlacesWrapper>
      <RichFieldGroup
        groupId={'ASIDE_B'}
        style={RICHFIELDS_CONFIG_DESKTOP.ASIDE_B}
      ></RichFieldGroup>
      <SimpleCard merchant={state.merchant} mergeField={9} isMail={true}>
        <Join />
      </SimpleCard>
    </aside>
  );
};

const Join = () => {
  return (
    <p>
      <span className="appointmentSmall">REJOINDRE</span> L'EQUIPE
    </p>
  );
};

const Prices = () => {
  return (
    <p>
      TARIFS <span className="appointmentSmall">&</span> PRESTATIONS
    </p>
  );
};

const Appointment = () => {
  return (
    <p>
      <span className="appointmentSmall">PRENDRE</span> RENDEZ VOUS
    </p>
  );
};

export default LocalPage;
