import React, { createRef, useEffect, useState } from "react";

import NearbyPlaces from "common/components/NearbyPlaces/NearbyPlaces";
import IconsMapper from "common/components/IconsMapper/IconsMapper";

import "./NearbyPlacesWrapper.css";

const NearbyPlacesDetails = ({ nearbyMerchants }) => {
  return (
    <ul>
      {nearbyMerchants.map((merchant) => {
        return (
          <li key={merchant.id}>
            <a href={merchant.localpageUrl}>
              <div className="nearbyPlacesDetailsName">{merchant.name}</div>
              <div>{merchant.formattedAddress}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const Child = ({ nearbyMerchants }) => {
  const details = createRef();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const displayDetails = () => {
    setIsDisplayed(!isDisplayed);
  };

  useEffect(() => {
    if (details.current) {
      details.current.style.display = isDisplayed ? "block" : "none";
    }
  }, [isDisplayed, details]);

  return (
    <section className="nearbyPlacesWrapperSection">
      <div
        className="nearbyPlacesWrapperContainer"
        onClick={() => displayDetails()}
      >
        <div className="nearbyPlacesCenter">
          <IconsMapper icon={'faLocationDot'}></IconsMapper>
        </div>
        <h4>SALONS A PROXIMITÉ</h4>
        <div
          className="nearbyPlacesCenter"
          style={
            isDisplayed
              ? {
                  transition: '0.3s',
                }
              : {
                  transform: 'rotate(90deg)',
                  transition: '0.3s',
                }
          }
        >
          <IconsMapper icon={'faChevronDown'}></IconsMapper>
        </div>
      </div>

      <div
        className="nearbyPlacesDetailsWrapper"
        ref={details}
        style={{ display: 'none' }}
      >
        <NearbyPlacesDetails
          nearbyMerchants={nearbyMerchants}
        ></NearbyPlacesDetails>
      </div>
    </section>
  );
};

const NearbyPlacesWrapper = () => {
  return <NearbyPlaces children={<Child></Child>}></NearbyPlaces>;
};

export default NearbyPlacesWrapper;
