import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { _ as e, e as r, a as t } from "../../../../_rollupPluginBabelHelpers-f0c52a37.js";
import n, { useContext as a, useState as o, useEffect as i } from "react";
import c from "google-map-react";
import s from "../../../../services/Map.js";
import l from "../../../../contexts/state-context.js";
import p from "../../../../contexts/map-context.js";
import m from "../../GoogleMap/GMap/Marker/Marker.js";
import "../../../../services/GMap.js";
import "axios";
import "../../../../services/Tracking/Tracking.js";
import "../../../../services/Tracking/FacebookPixel.js";
import "../../../../services/Tracking/Pl.js";
import "../../../../services/ApiRequests.js";
import "../../../../services/Tracking/GoogleAnalitycs.js";
import "../../../../services/Utils.js";
import "../../../../services/Tracking/GoogleTagManager.js";
import "../../../../services/Geoapify.js";
import "../../../../style-inject.es-1f59c1d0.js";
var u = function (u) {
  var g,
    f = u.mapSize,
    v = u.balloon,
    d = u.mapConfig,
    k = void 0 === d ? {
      styles: []
    } : d,
    h = u.markers,
    j = void 0 === h ? {} : h,
    y = {
      balloon: {
        style: (null == v ? void 0 : v.style) || {}
      },
      markers: {
        store: (null == j ? void 0 : j.store) || s.gmapService.storesIcon,
        user: (null == j ? void 0 : j.user) || s.gmapService.locationIcon
      }
    },
    L = a(p),
    x = e(L, 2),
    M = x[0],
    G = x[1],
    S = a(l),
    I = e(S, 1)[0],
    T = o({}),
    b = e(T, 2),
    w = b[0],
    z = b[1],
    C = o(s.gmapService.defaultConfig),
    R = e(C, 2),
    A = R[0],
    E = R[1],
    P = o(""),
    U = e(P, 2),
    _ = U[0],
    B = U[1],
    D = function () {
      var e = r(_regeneratorRuntime.mark(function e(r, n) {
        var a, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, s.gmapService.initGMap(I, r);
            case 2:
              a = e.sent, G(t(t({}, M), a)), M.placeLoc.lat && M.placeLoc.lng && (o = {
                lat: M.placeLoc.lat,
                lon: M.placeLoc.lng
              }, O(o)), K(r, n);
            case 6:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function (r, t) {
        return e.apply(this, arguments);
      };
    }(),
    K = function (e, r) {
      r.event.addDomListenerOnce(e, "idle", function () {
        r.event.addDomListener(window, "resize", function () {
          var e = s.gmapService.GMaps.placeLoc;
          e && s.gmapService.centerOnStores(I.merchants, e);
        });
      });
    },
    O = function () {
      var e = r(_regeneratorRuntime.mark(function e(r, t) {
        var n, a, o, i, c;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, s.gmapService.refreshMap(t, I.merchants, r);
            case 2:
              n = e.sent, a = n.lat, o = n.lng, i = n.zoom, c = n.place, z({
                lat: c.lat,
                lng: c.lng
              }), E({
                center: {
                  lat: a,
                  lng: o
                },
                zoom: i
              });
            case 9:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function (r, t) {
        return e.apply(this, arguments);
      };
    }();
  return i(function () {
    var e = M.placeId;
    if (e && M.scriptInited) {
      O(undefined, e);
    }
  }, [M.placeId]), i(function () {
    if (M.placeLoc.lat && M.placeLoc.lng && M.scriptInited) {
      var e = {
        lat: M.placeLoc.lat,
        lon: M.placeLoc.lng
      };
      O(e);
    }
  }, [M.placeLoc.lat, M.placeLoc.lng]), n.createElement("div", {
    style: f
  }, n.createElement(c, {
    bootstrapURLKeys: {
      key: M.apiKeys.default,
      libraries: ["places"]
    },
    zoom: A.zoom,
    center: A.center,
    yesIWantToUseGoogleMapApiInternals: !0,
    onGoogleApiLoaded: function (e) {
      var r = e.map,
        t = e.maps;
      return D(r, t);
    },
    onChildClick: function (e) {
      B(e);
    },
    options: function (e) {
      var r = s.gmapService.mapControlConfig;
      return t(t({}, r), k);
    }
  }, I.merchants && !(null != I && null !== (g = I.sl) && void 0 !== g && g.home_search) && n.createElement(m, {
    lat: w.lat,
    lng: w.lng,
    key: "user",
    icon: y.markers.user,
    config: y
  }), I.merchants && I.merchants.length > 0 && I.merchants.map(function (e) {
    return n.createElement(m, {
      lat: e.latitude,
      lng: e.longitude,
      merchant: e,
      icon: y.markers.store,
      displayBalloon: _ === e.id,
      key: e.id,
      config: y
    });
  })));
};
export { u as default };