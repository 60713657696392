import { c as e, d as a } from "../_rollupPluginBabelHelpers-f0c52a37.js";
var t = new (function () {
  function t() {
    a(this, t);
  }
  return e(t, [{
    key: "marshallState",
    value: function (e) {
      var a, t, n, d, i, l, _, r, s, o, g, c, m, p, x, h, y, u, k, v, b, f, w;
      e.sl && (a = {
        brand: {
          id: e.brand.id || "",
          name: e.brand.name || "",
          services: e.brand.services || {},
          logo_uri: e.brand.logo_uri || "",
          ongoingCampaigns: e.brand.ongoingCampaigns || {},
          segment: {
            facebookPixel: e.brand.segment.facebookPixel || "",
            googleAnalyticsId: e.brand.segment.googleAnalyticsId || "",
            googleMapId: e.brand.segment.googleMapId || "",
            googleTagId: e.brand.segment.googleTagId || "",
            knowledgeBase: e.brand.segment.knowledgeBase || []
          },
          placeCount: e.brand.placeCount || "",
          segmentId: e.brand.segmentId || ""
        },
        merchants: e.merchants || [],
        sl: {
          home_search: e.sl.home_search || !1,
          logo_uri: e.sl.logo_uri || "",
          settings: {
            gplace_id: e.sl.settings.gplace_id || "",
            placeLoc: e.sl.settings.placeLoc || void 0,
            apikeys: {
              gmaps: e.sl.settings.apikeys.gmaps || "",
              ganalytics: e.sl.settings.apikeys.ganalytics || "",
              gtagmanager: e.sl.settings.apikeys.gtagmanager || ""
            },
            age_gate: {
              enabled: e.sl.settings.age_gate.enabled || !1,
              cookie_domain: e.sl.settings.age_gate.cookie_domain || "",
              cookie_path: e.sl.settings.age_gate.cookie_path || "/"
            },
            display_search_shortcuts: e.sl.settings.display_search_shortcuts || !1,
            display_current_offers: e.sl.settings.display_current_offers || !1,
            autoUserGeolocation: e.sl.settings.autoUserGeolocation || !1
          },
          search_shortcuts: e.sl.search_shortcuts || [],
          metatags: {
            title: e.sl.metatags.title || "",
            desc: e.sl.metatags.desc || "",
            keywords: e.sl.metatags.keywords || ""
          },
          content: {
            main: {
              title: {
                mimetype: e.sl.content.main.title.mimetype || "",
                value: e.sl.content.main.title.value || ""
              },
              subtitle: {
                mimetype: e.sl.content.main.subtitle || "",
                value: e.sl.content.main.subtitle || ""
              }
            }
          }
        },
        event: {
          termsAndConditions: (null == e || null === (t = e.event) || void 0 === t ? void 0 : t.termsAndConditions) || "",
          description: (null == e || null === (n = e.event) || void 0 === n ? void 0 : n.description) || "",
          creative: {
            logo: {
              desktop: (null == e || null === (d = e.event) || void 0 === d || null === (i = d.creative) || void 0 === i || null === (l = i.logo) || void 0 === l ? void 0 : l.desktop) || "",
              mobile: (null == e || null === (_ = e.event) || void 0 === _ || null === (r = _.creative) || void 0 === r || null === (s = r.logo) || void 0 === s ? void 0 : s.mobile) || ""
            },
            banner: {
              desktop: (null == e || null === (o = e.event) || void 0 === o || null === (g = o.creative) || void 0 === g || null === (c = g.banner) || void 0 === c ? void 0 : c.desktop) || "",
              mobile: (null == e || null === (m = e.event) || void 0 === m || null === (p = m.creative) || void 0 === p || null === (x = p.banner) || void 0 === x ? void 0 : x.mobile) || ""
            }
          }
        }
      });
      e.localpage && (a = {
        event: {
          relatedEventCampaign: {
            id: e.campaign_id || ""
          },
          termsAndConditions: e.conditions || "",
          description: e.description || "",
          id: e.id || "",
          name: e.name || "",
          creative: {
            logo: {
              desktop: e.picture_uri || ""
            }
          },
          configuration: {
            dataCollection: {
              fulladdress: {
                mandatory: e.mandatory_fields.fulladdress
              },
              birthday: {
                mandatory: e.mandatory_fields.birthday
              },
              country: {
                mandatory: e.mandatory_fields.country
              },
              phone: {
                mandatory: e.mandatory_fields.phone
              },
              postalcode: {
                mandatory: e.mandatory_fields.postalcode
              },
              name: {
                mandatory: e.mandatory_fields.name
              },
              email: {
                mandatory: e.mandatory_fields.email
              },
              lastname: {
                mandatory: e.mandatory_fields.lastname
              }
            },
            ticketing: {
              obtainable: {
                from: e.start_date || "",
                to: e.end_date || ""
              },
              redeemable: {
                from: e.use_start_date || "",
                to: e.use_end_date || ""
              }
            }
          }
        },
        brand: {
          id: e.localpage.brand.id || "",
          name: e.localpage.brand.name || "",
          services: e.localpage.brand.services || {},
          logo_uri: e.localpage.brand.logo_uri || "",
          ongoingCampaigns: e.localpage.brand.ongoingCampaigns || {},
          segmentId: e.localpage.brand.segmentId || "",
          segment: {
            facebookPixel: (null === (h = e.localpage.brand.segment) || void 0 === h ? void 0 : h.facebookPixel) || "",
            googleAnalyticsId: (null === (y = e.localpage.brand.segment) || void 0 === y ? void 0 : y.googleAnalyticsId) || "",
            googleMapId: (null === (u = e.localpage.brand.segment) || void 0 === u ? void 0 : u.googleMapId) || "",
            googleTagId: (null === (k = e.localpage.brand.segment) || void 0 === k ? void 0 : k.googleTagId) || ""
          }
        },
        merchant: {
          id: e.localpage.merchant.id || "",
          name: e.localpage.merchant.name || "",
          type: e.localpage.merchant.type || "",
          phone: e.localpage.merchant.phone || "",
          description: e.localpage.merchant.description || "",
          reference: e.localpage.merchant.reference || "",
          website_uri: e.localpage.merchant.website_uri || "",
          revervation_uri: e.localpage.merchant.revervation_uri || "",
          address: {
            zip: e.localpage.merchant.address.zip || "",
            street2: e.localpage.merchant.address.street2 || "",
            street: e.localpage.merchant.address.street || "",
            number: e.localpage.merchant.address.number || "",
            country: e.localpage.merchant.address.country || "",
            city: e.localpage.merchant.address.city || ""
          },
          formattedAddress: e.localpage.merchant.formattedAddress || "",
          latitude: e.localpage.merchant.latitude || 0,
          longitude: e.localpage.merchant.longitude || 0,
          localpageUrl: e.localpage.merchant.localpageUrl || "",
          availableServices: e.localpage.merchant.availableServices || [],
          segments: e.localpage.merchant.segments || [],
          workingTime: {
            additionalDays: e.localpage.merchant.workingTime.additionalDays || [],
            workingDaysPerWeek: {
              monday: e.localpage.merchant.workingTime.workingDaysPerWeek.monday || [],
              tuesday: e.localpage.merchant.workingTime.workingDaysPerWeek.tuesday || [],
              wednesday: e.localpage.merchant.workingTime.workingDaysPerWeek.wednesday || [],
              thursday: e.localpage.merchant.workingTime.workingDaysPerWeek.thursday || [],
              friday: e.localpage.merchant.workingTime.workingDaysPerWeek.friday || [],
              saturday: e.localpage.merchant.workingTime.workingDaysPerWeek.saturday || [],
              sunday: e.localpage.merchant.workingTime.workingDaysPerWeek.sunday || []
            },
            open77: e.localpage.merchant.workingTime.open77 || !1,
            nonWorkingDays: e.localpage.merchant.workingTime.nonWorkingDays || [],
            forcedOpenUntil: {
              date: e.localpage.merchant.workingTime.forcedOpenUntil.date || "",
              desc: e.localpage.merchant.workingTime.forcedOpenUntil.desc || ""
            },
            forcedCloseUntil: {
              date: e.localpage.merchant.workingTime.forcedCloseUntil.date || "",
              desc: e.localpage.merchant.workingTime.forcedCloseUntil.desc || ""
            }
          },
          ongoingCampaigns: e.localpage.merchant.ongoingCampaigns || [],
          extended_data: {
            extended_string_data_22: e.localpage.merchant.extended_data.extended_string_data_22 || "",
            extended_string_data_23: e.localpage.merchant.extended_data.extended_string_data_23 || "",
            extended_string_data_24: e.localpage.merchant.extended_data.extended_string_data_24 || "",
            extended_string_data_25: e.localpage.merchant.extended_data.extended_string_data_25 || "",
            extended_string_data_26: e.localpage.merchant.extended_data.extended_string_data_26 || "",
            extended_string_data_27: e.localpage.merchant.extended_data.extended_string_data_27 || "",
            extended_string_data_28: e.localpage.merchant.extended_data.extended_string_data_28 || "",
            extended_string_data_29: e.localpage.merchant.extended_data.extended_string_data_29 || "",
            extended_string_data_30: e.localpage.merchant.extended_data.extended_string_data_30 || "",
            extended_string_data_31: e.localpage.merchant.extended_data.extended_string_data_31 || "",
            extended_string_data_32: e.localpage.merchant.extended_data.extended_string_data_32 || "",
            extended_string_data_33: e.localpage.merchant.extended_data.extended_string_data_33 || "",
            extended_string_data_34: e.localpage.merchant.extended_data.extended_string_data_34 || "",
            extended_string_data_35: e.localpage.merchant.extended_data.extended_string_data_35 || "",
            extended_string_data_36: e.localpage.merchant.extended_data.extended_string_data_36 || "",
            extended_string_data_37: e.localpage.merchant.extended_data.extended_string_data_37 || "",
            extended_string_data_38: e.localpage.merchant.extended_data.extended_string_data_38 || "",
            extended_string_data_39: e.localpage.merchant.extended_data.extended_string_data_39 || "",
            extended_string_data_40: e.localpage.merchant.extended_data.extended_string_data_40 || "",
            extended_string_data_21: e.localpage.merchant.extended_data.extended_string_data_21 || "",
            extended_string_data_20: e.localpage.merchant.extended_data.extended_string_data_20 || "",
            extended_string_data_19: e.localpage.merchant.extended_data.extended_string_data_19 || "",
            extended_string_data_18: e.localpage.merchant.extended_data.extended_string_data_18 || "",
            extended_string_data_17: e.localpage.merchant.extended_data.extended_string_data_17 || "",
            extended_string_data_16: e.localpage.merchant.extended_data.extended_string_data_16 || "",
            extended_string_data_15: e.localpage.merchant.extended_data.extended_string_data_15 || "",
            extended_string_data_14: e.localpage.merchant.extended_data.extended_string_data_14 || "",
            extended_string_data_13: e.localpage.merchant.extended_data.extended_string_data_13 || "",
            extended_string_data_12: e.localpage.merchant.extended_data.extended_string_data_12 || "",
            extended_string_data_11: e.localpage.merchant.extended_data.extended_string_data_11 || "",
            extended_string_data_10: e.localpage.merchant.extended_data.extended_string_data_10 || "",
            extended_string_data_9: e.localpage.merchant.extended_data.extended_string_data_9 || "",
            extended_string_data_8: e.localpage.merchant.extended_data.extended_string_data_8 || "",
            extended_string_data_7: e.localpage.merchant.extended_data.extended_string_data_7 || "",
            extended_string_data_6: e.localpage.merchant.extended_data.extended_string_data_6 || "",
            extended_string_data_5: e.localpage.merchant.extended_data.extended_string_data_5 || "",
            extended_string_data_4: e.localpage.merchant.extended_data.extended_string_data_4 || "",
            extended_string_data_3: e.localpage.merchant.extended_data.extended_string_data_3 || "",
            extended_string_data_2: e.localpage.merchant.extended_data.extended_string_data_2 || "",
            extended_string_data_1: e.localpage.merchant.extended_data.extended_string_data_1 || ""
          },
          closeMerchants: e.localpage.merchant.closeMerchants || []
        },
        lp: {
          rich_fields: e.localpage.lp.rich_fields,
          webpresence: {
            facebook: {
              page_id: "",
              page_name: "",
              page_avatar: "",
              page_is_claimed: !1
            },
            twitter: {
              account_id: "",
              account_name: "",
              account_avatar: ""
            }
          },
          settings: {
            apikeys: {
              gmaps: e.localpage.lp.settings.apikeys.gmaps || "",
              ganalytics: e.localpage.lp.settings.apikeys.ganalytics || "",
              gtagmanager: e.localpage.lp.settings.apikeys.gtagmanager || ""
            },
            age_gate: {
              enabled: e.localpage.lp.settings.age_gate.enabled || !1,
              cookie_domain: e.localpage.lp.settings.age_gate.cookie_domain || "",
              cookie_path: e.localpage.lp.settings.age_gate.cookie_path || "/"
            },
            features: {
              display_opening_hours: e.localpage.lp.settings.features.display_opening_hours || !1,
              display_newsletter_form: e.localpage.lp.settings.features.display_newsletter_form || !1,
              display_nearby_places: e.localpage.lp.settings.features.display_nearby_places || !1,
              display_search_shortcuts: e.localpage.lp.settings.features.display_search_shortcuts || !1,
              display_coupons: e.localpage.lp.settings.features.display_coupons || !1
            }
          },
          search_shortcuts: e.localpage.lp.search_shortcuts || [],
          metatags: {
            title: e.localpage.lp.metatags.title || "",
            desc: e.localpage.lp.metatags.desc || "",
            keywords: e.localpage.lp.metatags.keywords || ""
          },
          content: {
            main: {
              title: {
                mimetype: e.localpage.lp.content.main.title.mimetype || "",
                value: e.localpage.lp.content.main.title.value || ""
              },
              subtitle: {
                mimetype: e.localpage.lp.content.main.subtitle.mimetype || "",
                value: e.localpage.lp.content.main.subtitle.value || ""
              },
              block1: {
                mimetype: e.localpage.lp.content.main.block1.mimetype || "",
                value: e.localpage.lp.content.main.block1.value || ""
              },
              block2: {
                mimetype: e.localpage.lp.content.main.block2.mimetype || "",
                value: e.localpage.lp.content.main.block2.value || ""
              },
              block3: {
                mimetype: e.localpage.lp.content.main.block3.mimetype || "",
                value: e.localpage.lp.content.main.block3.value || ""
              },
              block4: {
                mimetype: e.localpage.lp.content.main.block4.mimetype || "",
                value: e.localpage.lp.content.main.block4.value || ""
              },
              block5: {
                mimetype: e.localpage.lp.content.main.block5.mimetype || "",
                value: e.localpage.lp.content.main.block5.value || ""
              },
              block6: {
                mimetype: e.localpage.lp.content.main.block6.mimetype || "",
                value: e.localpage.lp.content.main.block6.value || ""
              }
            },
            misc: {
              alternativeCity: {
                mimetype: e.localpage.lp.content.misc.alternativeCity.mimetype || "",
                value: e.localpage.lp.content.misc.alternativeCity.value || ""
              }
            }
          }
        }
      });
      e.lp && (a = {
        brand: {
          id: e.brand.id || "",
          name: e.brand.name || "",
          services: e.brand.services || {},
          logo_uri: e.brand.logo_uri || "",
          ongoingCampaigns: e.brand.ongoingCampaigns || {},
          segmentId: e.brand.segmentId || "",
          segment: {
            facebookPixel: (null === (v = e.brand.segment) || void 0 === v ? void 0 : v.facebookPixel) || "",
            googleAnalyticsId: (null === (b = e.brand.segment) || void 0 === b ? void 0 : b.googleAnalyticsId) || "",
            googleMapId: (null === (f = e.brand.segment) || void 0 === f ? void 0 : f.googleMapId) || "",
            googleTagId: (null === (w = e.brand.segment) || void 0 === w ? void 0 : w.googleTagId) || ""
          }
        },
        merchant: {
          id: e.merchant.id || "",
          name: e.merchant.name || "",
          type: e.merchant.type || "",
          phone: e.merchant.phone || "",
          description: e.merchant.description || "",
          reference: e.merchant.reference || "",
          website_uri: e.merchant.website_uri || "",
          revervation_uri: e.merchant.revervation_uri || "",
          address: {
            zip: e.merchant.address.zip || "",
            street2: e.merchant.address.street2 || "",
            street: e.merchant.address.street || "",
            number: e.merchant.address.number || "",
            country: e.merchant.address.country || "",
            city: e.merchant.address.city || ""
          },
          formattedAddress: e.merchant.formattedAddress || "",
          latitude: e.merchant.latitude || 0,
          longitude: e.merchant.longitude || 0,
          localpageUrl: e.merchant.localpageUrl || "",
          availableServices: e.merchant.availableServices || [],
          segments: e.merchant.segments || [],
          workingTime: {
            additionalDays: e.merchant.workingTime.additionalDays || [],
            workingDaysPerWeek: {
              monday: e.merchant.workingTime.workingDaysPerWeek.monday || [],
              tuesday: e.merchant.workingTime.workingDaysPerWeek.tuesday || [],
              wednesday: e.merchant.workingTime.workingDaysPerWeek.wednesday || [],
              thursday: e.merchant.workingTime.workingDaysPerWeek.thursday || [],
              friday: e.merchant.workingTime.workingDaysPerWeek.friday || [],
              saturday: e.merchant.workingTime.workingDaysPerWeek.saturday || [],
              sunday: e.merchant.workingTime.workingDaysPerWeek.sunday || []
            },
            open77: e.merchant.workingTime.open77 || !1,
            nonWorkingDays: e.merchant.workingTime.nonWorkingDays || [],
            forcedOpenUntil: {
              date: e.merchant.workingTime.forcedOpenUntil.date || "",
              desc: e.merchant.workingTime.forcedOpenUntil.desc || ""
            },
            forcedCloseUntil: {
              date: e.merchant.workingTime.forcedCloseUntil.date || "",
              desc: e.merchant.workingTime.forcedCloseUntil.desc || ""
            }
          },
          ongoingCampaigns: e.merchant.ongoingCampaigns || [],
          extended_data: {
            extended_string_data_22: e.merchant.extended_data.extended_string_data_22 || "",
            extended_string_data_23: e.merchant.extended_data.extended_string_data_23 || "",
            extended_string_data_24: e.merchant.extended_data.extended_string_data_24 || "",
            extended_string_data_25: e.merchant.extended_data.extended_string_data_25 || "",
            extended_string_data_26: e.merchant.extended_data.extended_string_data_26 || "",
            extended_string_data_27: e.merchant.extended_data.extended_string_data_27 || "",
            extended_string_data_28: e.merchant.extended_data.extended_string_data_28 || "",
            extended_string_data_29: e.merchant.extended_data.extended_string_data_29 || "",
            extended_string_data_30: e.merchant.extended_data.extended_string_data_30 || "",
            extended_string_data_31: e.merchant.extended_data.extended_string_data_31 || "",
            extended_string_data_32: e.merchant.extended_data.extended_string_data_32 || "",
            extended_string_data_33: e.merchant.extended_data.extended_string_data_33 || "",
            extended_string_data_34: e.merchant.extended_data.extended_string_data_34 || "",
            extended_string_data_35: e.merchant.extended_data.extended_string_data_35 || "",
            extended_string_data_36: e.merchant.extended_data.extended_string_data_36 || "",
            extended_string_data_37: e.merchant.extended_data.extended_string_data_37 || "",
            extended_string_data_38: e.merchant.extended_data.extended_string_data_38 || "",
            extended_string_data_39: e.merchant.extended_data.extended_string_data_39 || "",
            extended_string_data_40: e.merchant.extended_data.extended_string_data_40 || "",
            extended_string_data_21: e.merchant.extended_data.extended_string_data_21 || "",
            extended_string_data_20: e.merchant.extended_data.extended_string_data_20 || "",
            extended_string_data_19: e.merchant.extended_data.extended_string_data_19 || "",
            extended_string_data_18: e.merchant.extended_data.extended_string_data_18 || "",
            extended_string_data_17: e.merchant.extended_data.extended_string_data_17 || "",
            extended_string_data_16: e.merchant.extended_data.extended_string_data_16 || "",
            extended_string_data_15: e.merchant.extended_data.extended_string_data_15 || "",
            extended_string_data_14: e.merchant.extended_data.extended_string_data_14 || "",
            extended_string_data_13: e.merchant.extended_data.extended_string_data_13 || "",
            extended_string_data_12: e.merchant.extended_data.extended_string_data_12 || "",
            extended_string_data_11: e.merchant.extended_data.extended_string_data_11 || "",
            extended_string_data_10: e.merchant.extended_data.extended_string_data_10 || "",
            extended_string_data_9: e.merchant.extended_data.extended_string_data_9 || "",
            extended_string_data_8: e.merchant.extended_data.extended_string_data_8 || "",
            extended_string_data_7: e.merchant.extended_data.extended_string_data_7 || "",
            extended_string_data_6: e.merchant.extended_data.extended_string_data_6 || "",
            extended_string_data_5: e.merchant.extended_data.extended_string_data_5 || "",
            extended_string_data_4: e.merchant.extended_data.extended_string_data_4 || "",
            extended_string_data_3: e.merchant.extended_data.extended_string_data_3 || "",
            extended_string_data_2: e.merchant.extended_data.extended_string_data_2 || "",
            extended_string_data_1: e.merchant.extended_data.extended_string_data_1 || ""
          },
          closeMerchants: e.merchant.closeMerchants || []
        },
        lp: {
          rich_fields: e.lp.rich_fields,
          webpresence: {
            facebook: {
              page_id: "",
              page_name: "",
              page_avatar: "",
              page_is_claimed: !1
            },
            twitter: {
              account_id: "",
              account_name: "",
              account_avatar: ""
            }
          },
          settings: {
            apikeys: {
              gmaps: e.lp.settings.apikeys.gmaps || "",
              ganalytics: e.lp.settings.apikeys.ganalytics || "",
              gtagmanager: e.lp.settings.apikeys.gtagmanager || ""
            },
            age_gate: {
              enabled: e.lp.settings.age_gate.enabled || !1,
              cookie_domain: e.lp.settings.age_gate.cookie_domain || "",
              cookie_path: e.lp.settings.age_gate.cookie_path || "/"
            },
            features: {
              display_opening_hours: e.lp.settings.features.display_opening_hours || !1,
              display_newsletter_form: e.lp.settings.features.display_newsletter_form || !1,
              display_nearby_places: e.lp.settings.features.display_nearby_places || !1,
              display_search_shortcuts: e.lp.settings.features.display_search_shortcuts || !1,
              display_coupons: e.lp.settings.features.display_coupons || !1
            }
          },
          search_shortcuts: e.lp.search_shortcuts || [],
          metatags: {
            title: e.lp.metatags.title || "",
            desc: e.lp.metatags.desc || "",
            keywords: e.lp.metatags.keywords || ""
          },
          content: {
            main: {
              title: {
                mimetype: e.lp.content.main.title.mimetype || "",
                value: e.lp.content.main.title.value || ""
              },
              subtitle: {
                mimetype: e.lp.content.main.subtitle.mimetype || "",
                value: e.lp.content.main.subtitle.value || ""
              },
              block1: {
                mimetype: e.lp.content.main.block1.mimetype || "",
                value: e.lp.content.main.block1.value || ""
              },
              block2: {
                mimetype: e.lp.content.main.block2.mimetype || "",
                value: e.lp.content.main.block2.value || ""
              },
              block3: {
                mimetype: e.lp.content.main.block3.mimetype || "",
                value: e.lp.content.main.block3.value || ""
              },
              block4: {
                mimetype: e.lp.content.main.block4.mimetype || "",
                value: e.lp.content.main.block4.value || ""
              },
              block5: {
                mimetype: e.lp.content.main.block5.mimetype || "",
                value: e.lp.content.main.block5.value || ""
              },
              block6: {
                mimetype: e.lp.content.main.block6.mimetype || "",
                value: e.lp.content.main.block6.value || ""
              }
            },
            misc: {
              alternativeCity: {
                mimetype: e.lp.content.misc.alternativeCity.mimetype || "",
                value: e.lp.content.misc.alternativeCity.value || ""
              }
            }
          }
        }
      });
      return a.template = {
        type: e.template.type || "",
        colors: {
          dominant: e.template.colors.dominant || "",
          palette: e.template.colors.palette || []
        }
      }, a;
    }
  }]), t;
}())();
export { t as default };