import { c as e, d as i, g as a, h as r } from "../_rollupPluginBabelHelpers-f0c52a37.js";
var l = new WeakMap(),
  s = new (function () {
    function s() {
      i(this, s), a(this, l, {
        writable: !0,
        value: {
          richFields: {
            root: "/storelocator/richfields/",
            size: "small"
          }
        }
      });
    }
    return e(s, [{
      key: "srcSetRichFields",
      value: function () {
        var e = this,
          i = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "small",
          a = arguments.length > 1 ? arguments[1] : void 0;
        if (a) return a.fields.forEach(function (a) {
          var s = r(e, l).richFields;
          a.image && (a.image = a.image.replace(s.root + s.size, s.root + i));
        }), a;
      }
    }]), s;
  }())();
export { s as default };