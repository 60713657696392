import e from "react";
import s from "../../services/Map.js";
import i from "./Geoapify/GeoapifySearch/GeoapifySearch.js";
import "../../_rollupPluginBabelHelpers-f0c52a37.js";
import "../../services/GMap.js";
import "axios";
import "../../services/Tracking/Tracking.js";
import "../../services/Tracking/FacebookPixel.js";
import "../../services/Tracking/Pl.js";
import "../../services/ApiRequests.js";
import "../../services/Tracking/GoogleAnalitycs.js";
import "../../services/Utils.js";
import "../../services/Tracking/GoogleTagManager.js";
import "../../services/Geoapify.js";
import "../../index.esm-d54eecc2.js";
import "../../iconBase-0f0e1f0c.js";
import "../../style-inject.es-1f59c1d0.js";
import "../../contexts/map-context.js";
import "../../contexts/state-context.js";
import "../../customHooks/useDebounce.js";
import "../../customHooks/useKeyPress.js";
import "../../customHooks/useOnClickOutside.js";
var o = function (o) {
  return e.createElement("div", {
    style: {
      display: "flex",
      flex: 1
    }
  }, "googlemap" === s.apiType && e.createElement(i, o));
};
export { o as default };