import React, { createRef, useContext, useEffect } from "react";

import MapService from "common/services/Map";
import MapSearch from "common/components/Map/MapSearch";
import Map from "common/components/Map/Map";
import MapContext from "common/contexts/map-context";
import BasicButton from "common/components/Widgets/BasicButton/BasicButton";
import ApiRequests from "common/services/ApiRequests";
import StateContext from "common/contexts/state-context";
import TrackingService from "common/services/Tracking/Tracking";

import "./HomeSearch.css";

const HomeSearch = (props) => {
  const [state, setState] = useContext(StateContext);
  const [map, setMap] = useContext(MapContext);
  const searchBarRef = createRef();
  const { placeLoc } = state?.sl?.settings;

  useEffect(() => {
    if (props.withGoTo && searchBarRef.current) {
      searchBarRef.current.scrollIntoView(true);
    }
  });

  useEffect(() => {
    TrackingService.pageView('storelocator');
    if (placeLoc) {
      TrackingService.trackSearchedPlace();
    }
  }, [placeLoc]);

  const goToGeolocation = async () => {
    const geoLocInfos = await MapService.goToGeolocation();
    const newState = await ApiRequests.getBrands(geoLocInfos.pagination, state);
    setState(newState);
    setMap({
      ...map,
      placeId: geoLocInfos.placeId,
      placeLoc: {
        ...map.placeLoc,
        lat: geoLocInfos.pagination.from_lat,
        lng: geoLocInfos.pagination.from_lng
      }
    });
  };

  return (
    <>
      <Map mapSize={{ height: "0", width: "0" }} />
      <div className="homeSearchSearchBarContainer">
        <p className="homeSearchOurSaloon">NOS SALONS</p>
        <div className="homeSearchSearchBarWrapper">
          <h1>
            <div className="homeSearchTitleBold">Trouvez un salon Vog</div>{" "}
            proche de chez vous
          </h1>
          <div className="homeSearchSearchBarTools">
            <div className="homeSearchSearchMapS">
              <MapSearch
                input={{
                  placeholder: "Ville ou code postal",
                  style: {
                    height: "30px",
                    padding: "var(--gutter-m)",
                  },
                }}
                predictionsSelect={{
                  wrapper: {
                    style: {
                      zIndex: 1,
                    },
                  },
                }}
                button={{
                  iconDisabled: true,
                  value: "RECHERCHER",
                  style: {
                    backgroundColor: "var(--primary-color)",
                    fontWeight: "normal",
                    color: "black",
                    letterSpacing: "2px",
                    width: "fit-content",
                    fontSize: "var(--fontsize-m)",
                    padding: "25px",
                  },
                }}
              />
            </div>
            <p style={{ fontWeight: "bold" }}>- ou -</p>
            <BasicButton
              label="LOCALISEZ-MOI"
              icon={"faLocationDot"}
              hoverColor={"black"}
              hover={"var(--primary-color"}
              style={{
                fontWeight: "normal",
                color: "black",
                letterSpacing: "2px",
                height: "30px",
              }}
              onClick={() => goToGeolocation()}
            />
          </div>
        </div>
      </div>
      <div className="homeSearchAnchor" ref={searchBarRef}></div>
    </>
  );
};

export default HomeSearch;
