import e from "react";
import { s as t } from "../../../style-inject.es-1f59c1d0.js";
import i from "../../IconsMapper/IconsMapper.js";
import "../../../_rollupPluginBabelHelpers-f0c52a37.js";
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
t(".bulletPointListImageWrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 5px; }\n\n.bulletPointListImageContainer {\n  width: 40px;\n  height: 40px;\n  margin-right: 5px; }\n  .bulletPointListImageContainer img {\n    max-width: 100%; }\n");
var n = function (t) {
  var n = t.section,
    a = t.style,
    r = void 0 === a ? {} : a,
    l = {
      img: r.img || {},
      text: r.text || {},
      name: r.name || {},
      title: r.title || {}
    },
    m = n.fields.some(function (e) {
      return "" !== e.image;
    });
  return e.createElement("div", {
    className: "componentWrapper componentWrapperFlex"
  }, n.title && e.createElement("h3", {
    style: l.title
  }, n.icon && e.createElement(i, {
    icon: n.icon
  }), n.title), m ? n.fields.map(function (t, i) {
    return e.createElement("div", {
      key: i,
      className: "bulletPointListImageWrapper"
    }, e.createElement("div", {
      className: "bulletPointListImageContainer"
    }, t.image && e.createElement("img", {
      loading: "lazy",
      src: t.image,
      alt: "articlesPicture",
      style: l.img
    })), e.createElement("div", {
      style: l.name
    }, t.title));
  }) : e.createElement("ul", {
    className: "serviceWrapper"
  }, n.fields.map(function (t, i) {
    return e.createElement("li", {
      key: i,
      style: l.name
    }, t.title);
  })));
};
export { n as default };