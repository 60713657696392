import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { _ as e, e as r } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import { useContext as s, useState as t, useEffect as i } from "react";
import o from "../services/Map.js";
import a from "../contexts/state-context.js";
import "../services/GMap.js";
import "axios";
import "../services/Tracking/Tracking.js";
import "../services/Tracking/FacebookPixel.js";
import "../services/Tracking/Pl.js";
import "../services/ApiRequests.js";
import "../services/Tracking/GoogleAnalitycs.js";
import "../services/Utils.js";
import "../services/Tracking/GoogleTagManager.js";
import "../services/Geoapify.js";
var n = function () {
  var n = s(a),
    c = e(n, 1)[0],
    p = t(null),
    m = e(p, 2),
    u = m[0],
    l = m[1],
    v = c.sl.settings.autoUserGeolocation;
  return i(function () {
    var e = function () {
      var e = r(_regeneratorRuntime.mark(function e() {
        var r;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, o.handleAutoUserGeolocation(v);
            case 2:
              (r = e.sent) && l(r);
            case 4:
            case "end":
              return e.stop();
          }
        }, e);
      }));
      return function () {
        return e.apply(this, arguments);
      };
    }();
    v && c.sl.home_search && e();
  }, []), u;
};
export { n as default };