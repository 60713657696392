import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as t, b as r, _ as a, e as n, a as o, i as s } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import l from "axios";
import i from "./Tracking/Tracking.js";
import "./Tracking/FacebookPixel.js";
import "./Tracking/Pl.js";
import "./ApiRequests.js";
import "./Tracking/GoogleAnalitycs.js";
import "./Utils.js";
import "./Tracking/GoogleTagManager.js";
var c = {
    silver: [{
      elementType: "geometry",
      stylers: [{
        color: "#f5f5f5"
      }]
    }, {
      elementType: "labels.icon",
      stylers: [{
        visibility: "off"
      }]
    }, {
      elementType: "labels.text.fill",
      stylers: [{
        color: "#616161"
      }]
    }, {
      elementType: "labels.text.stroke",
      stylers: [{
        color: "#f5f5f5"
      }]
    }, {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#bdbdbd"
      }]
    }, {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{
        color: "#eeeeee"
      }]
    }, {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#757575"
      }]
    }, {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{
        color: "#e5e5e5"
      }]
    }, {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#9e9e9e"
      }]
    }, {
      featureType: "road",
      elementType: "geometry",
      stylers: [{
        color: "#ffffff"
      }]
    }, {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#757575"
      }]
    }, {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{
        color: "#dadada"
      }]
    }, {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#616161"
      }]
    }, {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#9e9e9e"
      }]
    }, {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{
        color: "#e5e5e5"
      }]
    }, {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{
        color: "#eeeeee"
      }]
    }, {
      featureType: "water",
      elementType: "geometry",
      stylers: [{
        color: "#c9c9c9"
      }]
    }, {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{
        color: "#9e9e9e"
      }]
    }]
  },
  p = function () {
    function p(e) {
      t(this, p), r(this, "GMaps", {
        apiKeys: {
          default: "AIzaSyACUQugRkTLbtDXB3j8sa7KAfgLDyVp77s",
          geoLocator: "AIzaSyCrjK4w-kp7tBqgaUwelN6syDmyMoqD54g"
        },
        placeService: {},
        autoCompleteService: {},
        geocoder: {},
        map: {},
        scriptInited: !1,
        placeId: "",
        placeLoc: {
          lat: "",
          lng: "",
          zoom: ""
        },
        placesFields: ["name", "geometry.location", "place_id", "formatted_address", "types"]
      }), r(this, "googleStaticCardBaseUrl", "https://www.google.com/maps/dir/"), r(this, "countrySearch", ["fr", "reu", "re"]), r(this, "locationIcon", "/frontboard-templates/templates/PLACELOOP-GENERIC-2022/static/gfx/map-marker.png"), r(this, "storesIcon", "/frontboard-templates/templates/PLACELOOP-GENERIC-2022/static/gfx/icon-stores.png"), r(this, "defaultScriptUrl", "https://maps.google.com/maps/api/js?v=3&libraries=places&key="), r(this, "geoLocateFallbackUrl", "https://www.googleapis.com/geolocation/v1/geolocate?key=".concat(this.GMaps.apiKeys.geoLocator)), r(this, "defaultConfig", {
        center: {
          lat: 46.227638,
          lng: 2.213749000000007
        },
        zoom: 6
      }), r(this, "_defaultZoomPerType", {
        country: 6,
        administrative_area_level_1: 9,
        administrative_area_level_2: 10,
        locality: 12,
        sublocality: 13,
        neighborhood: 14,
        establishment: 16,
        route: 16
      }), r(this, "askForResize", !1), r(this, "mapControlConfig", {
        mapTypeControl: !1,
        clickableIcons: !1,
        scaleControl: !1,
        streetViewControl: !1,
        rotateControl: !1,
        fullscreenControl: !1,
        zoomControl: !1,
        scrollwheel: !0
      }), r(this, "mapStyles", {
        silver: c.silver
      }), this.mapService = e;
    }
    var u, g, m, f, y, h, d, v, k, b, w, T, x, L, R;
    return e(p, [{
      key: "initGMap",
      value: (R = n(_regeneratorRuntime.mark(function e(t, r) {
        var a, n, s;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return this.GMaps = o(o({}, this.GMaps), {}, {
                autoCompleteService: new google.maps.places.AutocompleteService(),
                placeService: new google.maps.places.PlacesService(r),
                geocoder: new google.maps.Geocoder(),
                map: r
              }), t.sl && (t.sl.settings.placeLoc && (a = t.sl.settings.placeLoc, n = a.lat, s = a.lon, this.GMaps.placeLoc.lat = n, this.GMaps.placeLoc.lng = s), t.sl.settings.gplace_id && (this.GMaps.placeId = t.sl.settings.gplace_id)), this.GMaps.scriptInited = !0, e.abrupt("return", this.GMaps);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return R.apply(this, arguments);
      })
    }, {
      key: "getPlacePredictions",
      value: (L = n(_regeneratorRuntime.mark(function e(t) {
        var r,
          a = this;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return r = {
                input: t,
                componentRestrictions: {
                  country: this.countrySearch
                },
                fields: this.GMaps.placesFields
              }, e.abrupt("return", new Promise(function (e) {
                a.GMaps.autoCompleteService.getPlacePredictions(r, function (t) {
                  e(t || []);
                });
              }));
            case 2:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return L.apply(this, arguments);
      })
    }, {
      key: "getDetails",
      value: (x = n(_regeneratorRuntime.mark(function e(t) {
        var r = this;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.abrupt("return", new Promise(function (e) {
                var a = {
                  fields: r.GMaps.placesFields,
                  placeId: t
                };
                r.GMaps.placeService.getDetails(a, function (t, r) {
                  r == google.maps.places.PlacesServiceStatus.OK && e(t);
                });
              }));
            case 1:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e) {
        return x.apply(this, arguments);
      })
    }, {
      key: "searchFirstAddressForPresearch",
      value: (T = n(_regeneratorRuntime.mark(function e(t) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.getPlacePredictions(t);
            case 2:
              return e.abrupt("return", e.sent);
            case 3:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return T.apply(this, arguments);
      })
    }, {
      key: "goToLocationByPlaceName",
      value: (w = n(_regeneratorRuntime.mark(function e(t) {
        var r, a;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.getPlacePredictions(t);
            case 2:
              return r = e.sent, a = r[0].place_id, e.next = 6, this.goToLocationByPlaceId(a);
            case 6:
              return e.abrupt("return", e.sent);
            case 7:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return w.apply(this, arguments);
      })
    }, {
      key: "goToLocationByPlaceId",
      value: (b = n(_regeneratorRuntime.mark(function e(t) {
        var r, a;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.getDetails(t);
            case 2:
              return r = e.sent, i.trackSearchedPlace(r.name, r.formatted_address), this.GMaps.placeId = t, a = {
                skip: "0",
                number_of: this.mapService.numberOfMarkers,
                order_by: "distance",
                order_dir: "asc",
                from_lat: r.geometry.location.lat(),
                from_lng: r.geometry.location.lng()
              }, e.abrupt("return", {
                pagination: a,
                place: r.place_id
              });
            case 7:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return b.apply(this, arguments);
      })
    }, {
      key: "centerOnStore",
      value: function (e, t) {
        new google.maps.LatLngBounds().extend(new google.maps.LatLng(e, t));
      }
    }, {
      key: "centerOnStores",
      value: function (e, t) {
        var r = t.lat,
          a = t.lng,
          n = t.zoom;
        if (e) {
          var o = new google.maps.LatLngBounds();
          o.extend(new google.maps.LatLng(r, a));
          for (var s = !1, l = 0; l < e.length; l++) o.extend(new google.maps.LatLng(e[l].latitude, e[l].longitude)), s = !0;
          var i = {
            lat: this.GMaps.map.getCenter().lat(),
            lng: this.GMaps.map.getCenter().lng(),
            zoom: this.defaultConfig.zoom,
            bounds: o
          };
          return this.GMaps.map.fitBounds(o), s || void 0 === n || (i.zoom = n, this.GMaps.map.setZoom(n)), i;
        }
      }
    }, {
      key: "refreshMap",
      value: (k = n(_regeneratorRuntime.mark(function e(t, r, a) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (!a) {
                e.next = 4;
                break;
              }
              return e.next = 3, this.refreshMapByPlaceLoc(a, r);
            case 3:
            case 7:
              return e.abrupt("return", e.sent);
            case 4:
              if (!t) {
                e.next = 8;
                break;
              }
              return e.next = 7, this.refreshMapByPlaceId(t, r);
            case 8:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t, r) {
        return k.apply(this, arguments);
      })
    }, {
      key: "refreshMapByPlaceLoc",
      value: (v = n(_regeneratorRuntime.mark(function e(t, r) {
        var a, n, s, l;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return a = t.lat, n = t.lon, s = {
                lat: a,
                lng: n,
                zoom: 16
              }, this.GMaps.placeLoc = s, l = this.centerOnStores(r, s), e.abrupt("return", o(o({}, l), {}, {
                place: o({}, s)
              }));
            case 5:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return v.apply(this, arguments);
      })
    }, {
      key: "refreshMapByPlaceId",
      value: (d = n(_regeneratorRuntime.mark(function e(t, r) {
        var a, n, s;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.getDetails(t);
            case 2:
              return a = e.sent, n = {
                lat: a.geometry.location.lat(),
                lng: a.geometry.location.lng(),
                zoom: this.getDefaultZoomForPlace(a)
              }, this.GMaps.placeLoc = n, s = this.centerOnStores(r, n), e.abrupt("return", o(o({}, s), {}, {
                place: o({}, n)
              }));
            case 7:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return d.apply(this, arguments);
      })
    }, {
      key: "getMerchantGoogleMapURL",
      value: (h = n(_regeneratorRuntime.mark(function e(t, r) {
        var a, n, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (a = this.googleStaticCardBaseUrl, r.lat && r.lng) try {
                n = r.lat, o = r.lng, n && o && (a += n + "," + o + "/");
              } catch (e) {
                a += "/";
              } else a += "/";
              return t && t.latitude && t.longitude && !isNaN(t.latitude) && !isNaN(t.longitude) ? a += t.latitude + "," + t.longitude : t && t.formattedAddress && (a += encodeURI(t.formattedAddress)), e.abrupt("return", {
                url: a,
                target: "_blank"
              });
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return h.apply(this, arguments);
      })
    }, {
      key: "getGeocode",
      value: (y = n(_regeneratorRuntime.mark(function e(t, r) {
        var a,
          n = this;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return a = {
                latLng: new google.maps.LatLng(t, r)
              }, e.abrupt("return", new Promise(function (e) {
                n.GMaps.geocoder.geocode(a, function (t, r) {
                  t.length > 0 && e(t[0]);
                });
              }));
            case 2:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e, t) {
        return y.apply(this, arguments);
      })
    }, {
      key: "handleGeolocationFallback",
      value: (f = n(_regeneratorRuntime.mark(function e(t, r) {
        var a, n, o, s;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              a = {
                pagination: {
                  from_lat: null,
                  from_lng: null
                }
              }, e.t0 = t.code, e.next = e.t0 === t.TIMEOUT ? 4 : e.t0 === t.PERMISSION_DENIED ? 5 : e.t0 === t.POSITION_UNAVAILABLE ? 19 : 20;
              break;
            case 4:
              return e.abrupt("break", 20);
            case 5:
              return e.prev = 5, e.next = 8, this.goToGeolocationFallbackMethod(r);
            case 8:
              n = e.sent, o = n.lat, s = n.lng, a.pagination.from_lat = o, a.pagination.from_lng = s, e.next = 18;
              break;
            case 15:
              e.prev = 15, e.t1 = e.catch(5), console.log(e.t1);
            case 18:
            case 19:
              return e.abrupt("break", 20);
            case 20:
              return e.abrupt("return", a);
            case 21:
            case "end":
              return e.stop();
          }
        }, e, this, [[5, 15]]);
      })), function (e, t) {
        return f.apply(this, arguments);
      })
    }, {
      key: "goToGeolocationFallbackMethod",
      value: (m = n(_regeneratorRuntime.mark(function e() {
        var t,
          r,
          a,
          n = arguments;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return t = !(n.length > 0 && void 0 !== n[0]) || n[0], e.next = 3, l.post(this.geoLocateFallbackUrl);
            case 3:
              return r = e.sent, a = r.data, t && i.trackGeolocatedPlace(a.location.lat, a.location.lng), e.abrupt("return", {
                lat: a.location.lat,
                lng: a.location.lng
              });
            case 7:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function () {
        return m.apply(this, arguments);
      })
    }, {
      key: "getDefaultZoomForPlace",
      value: function (e) {
        for (var t = void 0, r = 0; r < e.types.length && void 0 === (t = this._defaultZoomPerType[e.types[r]]); r++);
        return void 0 === t && (t = 16), t;
      }
    }, {
      key: "getStaticMapUrl",
      value: function (e, t, r) {
        var n = e.lat,
          o = e.lng,
          s = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          l = Object.entries(s).map(function (e) {
            var t = a(e, 2),
              r = t[0],
              n = t[1];
            return "".concat(r, ":").concat(n);
          }).join("|");
        return "https://maps.google.com/maps/api/staticmap?center=".concat(n, ",").concat(o, "&markers=icon:").concat(this.storesIcon, "%7C").concat(n, ",").concat(o, "&zoom=16&size=").concat(t, "x").concat(r, "&scale=1&panControl=false&scrollwheel=false&streetViewControl=false&mapTypeControl=false&key=").concat(this.GMaps.apiKeys.default, "&style=").concat(l);
      }
    }, {
      key: "createLinkForStore",
      value: (g = n(_regeneratorRuntime.mark(function e(t) {
        var r, a, n, o, s, l, i;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.getDetails(this.GMaps.placeId);
            case 2:
              return r = e.sent, a = {
                latitude: r.geometry.location.lat(),
                longitude: r.geometry.location.lng()
              }, n = a.latitude + "," + a.longitude, o = t.latitude + "," + t.longitude, s = encodeURIComponent(n), l = encodeURIComponent(o), i = "/".concat(t.id, "?a=").concat(s, "&b=").concat(l), e.abrupt("return", i);
            case 10:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return g.apply(this, arguments);
      })
    }, {
      key: "createLinksForStores",
      value: (u = n(_regeneratorRuntime.mark(function e(t) {
        var r, a, n, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              r = s(t), e.prev = 1, r.s();
            case 3:
              if ((a = r.n()).done) {
                e.next = 11;
                break;
              }
              return n = a.value, e.next = 7, this.createLinkForStore(n);
            case 7:
              o = e.sent, n.localpageUrl = o;
            case 9:
              e.next = 3;
              break;
            case 11:
              e.next = 16;
              break;
            case 13:
              e.prev = 13, e.t0 = e.catch(1), r.e(e.t0);
            case 16:
              return e.prev = 16, r.f(), e.finish(16);
            case 19:
            case "end":
              return e.stop();
          }
        }, e, this, [[1, 13, 16, 19]]);
      })), function (e) {
        return u.apply(this, arguments);
      })
    }]), p;
  }();
export { p as default };