import { _ as r } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import { useState as t, useEffect as e } from "react";
var o = function (o, n) {
  var u = t(o),
    a = r(u, 2),
    i = a[0],
    f = a[1];
  return e(function () {
    var r = setTimeout(function () {
      f(o);
    }, n);
    return function () {
      clearTimeout(r);
    };
  }, [o, n]), i;
};
export { o as default };