import React, { createRef, useContext } from "react";

import Map from 'common/components/Map/Map';
import MapService from 'common/services/Map';
import StateContext from 'common/contexts/state-context';
import BasicButton from 'common/components/Widgets/BasicButton/BasicButton';


import "./MerchantList.css";

const MerchantList = () => {
  const [state] = useContext(StateContext);
  const mapRef = createRef();

  return (
    <section className="merchantListWrapper">
      <article className="merchantListArticleWrapper">
        <div className="merchantListMapComponentWrapper" ref={mapRef}>
          <Map
            mapSize={{
              height: "100%",
              width: "100%",
            }}
            mapConfig={{
              scrollwheel: false,
              styles: MapService.mapStyles.silver
            }}
          />
        </div>
        {state.merchants && (
          <ul className="merchantListMerchantComponentWrapper">
            {state.merchants
              .sort((a, b) => {
                return a.distance - b.distance;
              })
              .sort((a, b) => {
                return a.name.toLowerCase() - b.name.toLowerCase();
              })
              .map((merchant, index) => {
                return (
                  <li className="merchantCardWrapper">
                    <div className="merchantCardImageWrapper">
                      <div className="merchantCardImageDistance">
                        {merchant.distance.toPrecision(3)} Km
                      </div>
                      <img
                        src="https://qr28-media.s3.amazonaws.com/brands/5602196670067/contentportal/items/41103374864777--orig-preview"
                        alt="hairdresser local store"
                        loading="lazy"
                      />
                    </div>
                    <div className="merchantCardInfosWrapper">
                      <div className="merchantCardInfosNumberWrapper">
                        <div className="merchantCardInfosNumberTag">
                          {index + 1}
                        </div>
                        <div className="merchantCardInfosNumberBarWrapper">
                          <div className="merchantCardInfosNumberBar" />
                        </div>
                      </div>
                      <div className="merchantCardInfosText">
                        <h2>
                          <a href={merchant.localpageUrl}>{merchant.name}</a>
                        </h2>
                        <address>
                          <p>{merchant.address.street}</p>
                          <p>
                            {merchant.address.zip} {merchant.address.city}
                          </p>
                        </address>
                        <p>Tél : {merchant.phone}</p>
                      </div>
                    </div>
                    <div className="merchantCardCtas">
                      <BasicButton
                        label={"VOIR LA FICHE SALON"}
                        icon={"faNoteSticky"}
                        href={merchant.localpageUrl}
                        type={"secondary"}
                        isBig={true}
                        hoverColor={"black"}
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      />
                      {merchant.extended_data.extended_string_data_3 && (
                        <BasicButton
                          label={"PRENDRE RENDEZ VOUS"}
                          icon={"faCalendarDays"}
                          isBig={true}
                          isTargetBlank={"_blank"}
                          href={merchant.extended_data.extended_string_data_3}
                          type={"primary"}
                          hoverColor={"black"}
                          style={{
                            fontWeight: "normal",
                            color: "black",
                          }}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
      </article>
    </section>
  );
};

export default MerchantList;
