import e, { useEffect as t } from "react";
import i from "../../../services/Tracking/Tracking.js";
import "../../../_rollupPluginBabelHelpers-f0c52a37.js";
import "../../../services/Tracking/FacebookPixel.js";
import "../../../services/Tracking/Pl.js";
import "../../../services/ApiRequests.js";
import "axios";
import "../../../services/Tracking/GoogleAnalitycs.js";
import "../../../services/Utils.js";
import "../../../services/Tracking/GoogleTagManager.js";
var o = function (o) {
  var r = o.hardcodedKey,
    n = i.getServiceKey("facebookPixel");
  return n || (n = r, i.setServiceKey("facebookPixel", r)), t(function () {
    if (n) try {
      !function () {
        var e,
          t,
          i,
          o,
          r,
          n,
          c = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
        0 !== c.length && (e = window, t = document, i = "script", e.fbq || (o = e.fbq = function () {
          o.callMethod ? o.callMethod.apply(o, arguments) : o.queue.push(arguments);
        }, e._fbq || (e._fbq = o), o.push = o, o.loaded = !0, o.version = "2.0", o.queue = [], (r = t.createElement(i)).async = !0, r.src = "https://connect.facebook.net/en_US/fbevents.js", (n = t.getElementsByTagName(i)[0]).parentNode.insertBefore(r, n)), c.forEach(function (e) {
          fbq("init", e);
        }));
      }(n.split(";").filter(function (e) {
        return "" !== e;
      }));
    } catch (e) {
      console.log(e);
    }
    i.getInitedStatus("facebookPixel") || window.fbq && i.setTrigger("facebookPixel", window.fbq);
  }, []), e.createElement(e.Fragment, null);
};
export { o as default };