import e, { useEffect as r, createRef as n } from "react";
import { s as a } from "../../../../../style-inject.es-1f59c1d0.js";
a(".marker .pin {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  user-select: none;\n  cursor: pointer;\n  transform: translate(-50%, -100%); }\n  .marker .pin:hover {\n    z-index: 1; }\n\n.marker .balloonWrapper {\n  transition: opacity 0.3s;\n  padding-right: 0px;\n  padding-bottom: 0px;\n  max-width: 332px;\n  max-height: 605px;\n  min-width: 150px;\n  opacity: 0;\n  position: absolute;\n  box-sizing: border-box;\n  overflow: hidden;\n  top: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  z-index: 1; }\n  .marker .balloonWrapper .balloon {\n    background-color: var(--background-color);\n    box-shadow: var(--boxshadow-reg);\n    border-radius: 8px;\n    padding: var(--gutter-m); }\n    .marker .balloonWrapper .balloon a {\n      color: inherit; }\n  .marker .balloonWrapper .arrow-down {\n    width: 0;\n    height: 0;\n    border-left: var(--gutter-m) solid transparent;\n    border-right: var(--gutter-m) solid transparent;\n    border-top: var(--gutter-m) solid var(--background-color); }\n");
var t = function (a) {
  var t = n(),
    l = n();
  return r(function () {
    if (a.displayBalloon) {
      var e = t.current.offsetHeight;
      if (l.current) {
        var r = "translate(-50%, calc(-100% - ".concat(e + 5 + "px", "))");
        l.current.style.transform = r, l.current.style.opacity = 1;
      }
    }
  }, [a.displayBalloon]), e.createElement("div", {
    className: "marker"
  }, a.merchant && a.displayBalloon && e.createElement("div", {
    className: "balloonWrapper",
    ref: l
  }, e.createElement("div", {
    className: "balloon",
    style: a.config.balloon.style
  }, e.createElement("a", {
    href: a.merchant.localpageUrl
  }, e.createElement("b", null, a.merchant.name), e.createElement("br", null), e.createElement("span", null, a.merchant.address.number, " ", a.merchant.address.street), e.createElement("br", null), a.merchant.address.street2 && e.createElement("span", null, a.merchant.address.street2), e.createElement("span", null, a.merchant.address.zip, " - ", a.merchant.address.city), e.createElement("br", null))), e.createElement("div", {
    className: "arrow-down"
  })), e.createElement("img", {
    loading: "lazy",
    className: "pin",
    src: a.icon,
    alt: "google marker",
    ref: t
  }));
};
export { t as default };