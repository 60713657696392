import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as t, b as r, e as n } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import a from "./GMap.js";
import i from "./Geoapify.js";
import o from "./Utils.js";
import s from "./Tracking/Tracking.js";
import "axios";
import "./ApiRequests.js";
import "./Tracking/FacebookPixel.js";
import "./Tracking/Pl.js";
import "./Tracking/GoogleAnalitycs.js";
import "./Tracking/GoogleTagManager.js";
var u = function () {
    function s() {
      t(this, s), r(this, "numberOfMarkers", 20), r(this, "apiType", ""), r(this, "initialContext", {}), this.mapUtils = new c(this), this.gmapService = new a(this), this.geoapifyService = new i(this);
    }
    var u, p, g, l, m, f;
    return e(s, [{
      key: "initMap",
      value: function (e) {
        var t = "AIzaSyACUQugRkTLbtDXB3j8sa7KAfgLDyVp77s",
          r = "";
        if (e.sl && (r = e.sl.settings.apikeys.gmaps) && (t = r), e.lp && (r = e.lp.settings.apikeys.gmaps) && (t = r), t) {
          var n = "googlemap";
          return this.apiType = n, this.mapUtils.apiType = n, this.gmapService.GMaps.apiKeys.default = t, void (this.initialContext = this.gmapService.GMaps);
        }
      }
    }, {
      key: "askForResize",
      get: function () {
        if ("googlemap" === this.apiType) return this.gmapService.askForResize;
      },
      set: function (e) {
        "googlemap" === this.apiType && (this.gmapService.askForResize = e);
      }
    }, {
      key: "mapStyles",
      get: function () {
        if ("googlemap" === this.apiType) return this.gmapService.mapStyles;
      }
    }, {
      key: "getItinaryURL",
      value: (f = n(_regeneratorRuntime.mark(function e(t, r) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if ("googlemap" !== this.apiType) {
                e.next = 4;
                break;
              }
              return e.next = 3, this.gmapService.getMerchantGoogleMapURL(t, r);
            case 3:
              return e.abrupt("return", e.sent);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return f.apply(this, arguments);
      })
    }, {
      key: "goToLocationByPlaceName",
      value: (m = n(_regeneratorRuntime.mark(function e(t) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if ("googlemap" !== this.apiType) {
                e.next = 4;
                break;
              }
              return e.next = 3, this.geoapifyService.goToLocationByPlaceName(t);
            case 3:
              return e.abrupt("return", e.sent);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return m.apply(this, arguments);
      })
    }, {
      key: "getDetails",
      value: (l = n(_regeneratorRuntime.mark(function e(t) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if ("googlemap" !== this.apiType) {
                e.next = 4;
                break;
              }
              return e.next = 3, this.gmapService.getDetails(t);
            case 3:
              return e.abrupt("return", e.sent);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return l.apply(this, arguments);
      })
    }, {
      key: "createLinksForStores",
      value: (g = n(_regeneratorRuntime.mark(function e(t) {
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if ("googlemap" !== this.apiType) {
                e.next = 4;
                break;
              }
              return e.next = 3, this.gmapService.createLinksForStores(t);
            case 3:
              return e.abrupt("return", e.sent);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e) {
        return g.apply(this, arguments);
      })
    }, {
      key: "goToGeolocation",
      value: (p = n(_regeneratorRuntime.mark(function e(t, r) {
        var n;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, this.mapUtils.getUserGeolocation(t, r);
            case 2:
              return n = e.sent, e.abrupt("return", n);
            case 4:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return p.apply(this, arguments);
      })
    }, {
      key: "handleAutoUserGeolocation",
      value: (u = n(_regeneratorRuntime.mark(function e(t) {
        var r, n, a, i, s, u, c, p, g;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (e.prev = 0, t) {
                e.next = 3;
                break;
              }
              return e.abrupt("return", null);
            case 3:
              return e.next = 7, this.mapUtils.getUserGeolocation(!0, !0);
            case 7:
              if (r = e.sent) {
                e.next = 10;
                break;
              }
              return e.abrupt("return", null);
            case 10:
              return n = r.pagination, a = n.from_lat, i = n.from_lng, e.next = 14, this.geoapifyService.getReverseGeocoding(a, i);
            case 14:
              if (0 !== (s = e.sent).length) {
                e.next = 17;
                break;
              }
              return e.abrupt("return", null);
            case 17:
              return u = s[0], c = u.formatted, p = o.slugify(c), g = "/location/".concat(p), e.abrupt("return", g);
            case 24:
              return e.prev = 24, e.t0 = e.catch(0), console.warn("Error handling auto user geolocation", e.t0), e.abrupt("return");
            case 28:
            case "end":
              return e.stop();
          }
        }, e, this, [[0, 24]]);
      })), function (e) {
        return u.apply(this, arguments);
      })
    }]), s;
  }(),
  c = function () {
    function r(e) {
      t(this, r), this.mapService = e;
    }
    var a;
    return e(r, [{
      key: "getUserGeolocation",
      value: (a = n(_regeneratorRuntime.mark(function e() {
        var t,
          r,
          a,
          i = this,
          o = arguments;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (t = !(o.length > 0 && void 0 !== o[0]) || o[0], r = o.length > 1 && void 0 !== o[1] && o[1], a = {
                pagination: {
                  skip: "0",
                  number_of: this.mapService.numberOfMarkers,
                  order_by: "distance",
                  order_dir: "asc",
                  from_lat: "",
                  from_lng: ""
                },
                placeId: ""
              }, !navigator.geolocation) {
                e.next = 5;
                break;
              }
              return e.abrupt("return", new Promise(function (e) {
                navigator.geolocation.getCurrentPosition(function () {
                  var r = n(_regeneratorRuntime.mark(function r(n) {
                    return _regeneratorRuntime.wrap(function (r) {
                      for (;;) switch (r.prev = r.next) {
                        case 0:
                          if (t) try {
                            s.trackGeolocatedPlace(n.coords.latitude, n.coords.longitude);
                          } catch (e) {
                            console.warn("Error tracking geolocated place", e);
                          }
                          a.pagination.from_lat = n.coords.latitude, a.pagination.from_lng = n.coords.longitude, a.placeId = void 0, e(a);
                        case 5:
                        case "end":
                          return r.stop();
                      }
                    }, r);
                  }));
                  return function (e) {
                    return r.apply(this, arguments);
                  };
                }(), function () {
                  var o = n(_regeneratorRuntime.mark(function n(o) {
                    var s, u;
                    return _regeneratorRuntime.wrap(function (n) {
                      for (;;) switch (n.prev = n.next) {
                        case 0:
                          if (!r) {
                            n.next = 3;
                            break;
                          }
                          return e(a), n.abrupt("return");
                        case 3:
                          if ("googlemap" !== i.mapService.apiType) {
                            n.next = 10;
                            break;
                          }
                          return n.next = 6, i.mapService.gmapService.handleGeolocationFallback(o, t);
                        case 6:
                          s = n.sent, u = s.pagination, a.pagination.from_lat = u.from_lat, a.pagination.from_lng = u.from_lng;
                        case 10:
                          e(a);
                        case 11:
                        case "end":
                          return n.stop();
                      }
                    }, n);
                  }));
                  return function (e) {
                    return o.apply(this, arguments);
                  };
                }());
              }));
            case 5:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function () {
        return a.apply(this, arguments);
      })
    }]), r;
  }(),
  p = new u();
export { p as default };