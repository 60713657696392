import { _ as e } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import t, { useContext as l } from "react";
import a from "../../contexts/state-context.js";
import { s as n } from "../../style-inject.es-1f59c1d0.js";
n(".nearbyPlaces {\n  width: 100%; }\n  .nearbyPlaces h3 {\n    text-transform: uppercase;\n    margin-bottom: var(--gutter-l); }\n  .nearbyPlaces h4 {\n    margin-bottom: var(--gutter-m);\n    font-size: var(--fontsize-m);\n    display: flex;\n    align-items: center;\n    text-align: justify; }\n  .nearbyPlaces > ul {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between; }\n  .nearbyPlaces li address {\n    margin-bottom: var(--gutter-m); }\n    .nearbyPlaces li address p {\n      overflow: hidden;\n      display: -webkit-box;\n      -webkit-line-clamp: 1;\n      -webkit-box-orient: vertical; }\n\n.nearbyPlacesPicture {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-bottom: var(--gutter-m); }\n  .nearbyPlacesPicture img {\n    max-width: 100px; }\n");
var r = function (n) {
  var r = n.title,
    s = n.img,
    i = n.styles,
    c = void 0 === i ? {} : i,
    m = n.children,
    d = {
      title: {
        label: (null == r ? void 0 : r.label) || "MAGASINS A PROXIMITE",
        style: (null == r ? void 0 : r.style) || {}
      },
      img: {
        alt: (null == s ? void 0 : s.alt) || "image du magasin"
      },
      styles: {
        imageWrapper: (null == c ? void 0 : c.imageWrapper) || {},
        address: (null == c ? void 0 : c.address) || {},
        ul: (null == c ? void 0 : c.ul) || {},
        li: (null == c ? void 0 : c.li) || {}
      }
    },
    o = !1,
    u = [],
    y = l(a),
    p = e(y, 1)[0];
  return p.lp && (o = p.lp.settings.features.display_nearby_places, u = p.merchant.closeMerchants), t.createElement(t.Fragment, null, m ? t.createElement(t.Fragment, null, t.Children.map(m, function (e) {
    return t.cloneElement(e, {
      nearbyMerchants: u
    });
  })) : t.createElement(t.Fragment, null, o && t.createElement("section", {
    className: "nearbyPlaces"
  }, t.createElement("h3", {
    style: d.title.style
  }, d.title.label), t.createElement("ul", {
    className: "sl-flex sl-flex-wrap sl-gap",
    style: d.styles.ul
  }, u.map(function (e) {
    return t.createElement("li", {
      key: e.id,
      style: d.styles.li
    }, t.createElement("a", {
      href: "/".concat(e.id),
      style: {
        color: "inherit"
      }
    }, t.createElement("div", {
      className: "nearbyPlacesPicture",
      style: d.styles.imageWrapper
    }, t.createElement("img", {
      src: p.brand.logo_uri,
      alt: d.img.alt
    })), t.createElement("h4", null, e.name), e.address && t.createElement("address", {
      style: d.styles.address
    }, t.createElement("p", null, e.address.number, " ", e.address.street), t.createElement("p", null, e.address.zip, " ", e.address.city))));
  })))));
};
export { r as default };