import e from "react";
import { s as r } from "../../../style-inject.es-1f59c1d0.js";
import i from "../../IconsMapper/IconsMapper.js";
import t from "../../../services/Images.js";
import a from "../../../services/Tracking/Tracking.js";
import s from "../../../services/Url.js";
import "../../../_rollupPluginBabelHelpers-f0c52a37.js";
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
import "../../../services/Tracking/FacebookPixel.js";
import "../../../services/Tracking/Pl.js";
import "../../../services/ApiRequests.js";
import "axios";
import "../../../services/Tracking/GoogleAnalitycs.js";
import "../../../services/Utils.js";
import "../../../services/Tracking/GoogleTagManager.js";
r(".richFieldImageListWrapperImageList {\n  display: flex;\n  align-items: center;\n  margin-bottom: var(--gutter-s); }\n\n.richFieldImageListWrapper img {\n  width: 100%;\n  overflow: hidden; }\n\n.richFieldImageListWrapper {\n  width: 40px;\n  margin-right: var(--gutter-m); }\n");
var m = function (r) {
  var m = r.section,
    n = r.style,
    c = void 0 === n ? {} : n,
    o = r.classes,
    l = void 0 === o ? {} : o,
    p = r.tracking,
    g = {
      title: c.title || {},
      img: c.img || {},
      imgWrapper: c.imgWrapper || {},
      imgContainer: c.imgContainer || {},
      wrapper: c.wrapper || {},
      srcSet: c.srcSet || "small"
    },
    d = {
      title: l.title || "",
      img: l.img || "",
      imgWrapper: l.imgWrapper || "",
      imgContainer: l.imgContainer || "",
      wrapper: l.wrapper || ""
    },
    f = function () {
      if (p) {
        var e = p.merchant,
          r = p.brand,
          i = p.trackerName;
        a.trackEvent(e, r, {
          trackingEventName: i
        });
      }
    };
  return m = t.srcSetRichFields(g.srcSet, m), e.createElement("div", {
    style: g.wrapper,
    className: "componentWrapper componentWrapperFlex " + d.wrapper
  }, m.title && e.createElement("h2", {
    style: g.title,
    className: d.title
  }, m.icon && e.createElement(i, {
    icon: m.icon
  }), m.title), e.createElement("div", {
    className: "richFieldImageListWrapperImageList " + d.imgContainer,
    style: g.imgContainer
  }, m.fields.map(function (r, i) {
    return r.cta_target = s.modifyHTTP(r.cta_target), r.image = s.modifyHTTP(r.image), e.createElement("div", {
      className: "richFieldImageListWrapper " + d.imgWrapper,
      style: g.imgWrapper,
      key: i
    }, r.cta_target ? e.createElement("a", {
      href: r.cta_target,
      target: "_blank",
      style: {
        cursor: "pointer"
      },
      onClick: f
    }, e.createElement("img", {
      loading: "lazy",
      src: r.image,
      style: g.img,
      className: d.img,
      alt: r.desc
    })) : e.createElement("img", {
      loading: "lazy",
      src: r.image,
      style: g.img,
      className: d.img,
      alt: r.desc
    }));
  })));
};
export { m as default };