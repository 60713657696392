import React from 'react';
import IconsMapper from 'common/components/IconsMapper/IconsMapper';
import './SimpleCard.css';

const SimpleCard = ({
  merchant,
  icon,
  children,
  mergeField,
  color,
  isMail,
}) => {
  const mergeFieldIndex = 'extended_string_data_' + mergeField;
  const url = merchant.extended_data[mergeFieldIndex];

  return (
    <>
      {url && (
        <section
          className="simpleCardWrapper"
          style={color ? { backgroundColor: color } : {}}
        >
          <a
            href={isMail ? 'mailto:' + url : url}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <div className="simpleCardWrapperContainer">
              {icon && (
                <div className="simpleCardWrapperIcon">
                  <IconsMapper icon={icon}></IconsMapper>
                </div>
              )}
              <h4 className="simpleCardTitle">{children}</h4>
            </div>
          </a>
        </section>
      )}
    </>
  );
};

export default SimpleCard;
