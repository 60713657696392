import { G as t } from "./iconBase-0f0e1f0c.js";
function a(a) {
  return t({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24"
    },
    child: [{
      tag: "path",
      attr: {
        d: "M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
      }
    }]
  })(a);
}
export { a as B };