import { _ as e } from "../../../_rollupPluginBabelHelpers-f0c52a37.js";
import r, { useState as n, useEffect as t, createRef as o } from "react";
import a from "../../IconsMapper/IconsMapper.js";
import l from "../Spinner/Spinner.js";
import { s as i } from "../../../style-inject.es-1f59c1d0.js";
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
i(".buttonWrapper {\n  cursor: pointer;\n  display: flex;\n  transition: background-color 0.3s;\n  min-height: 38px; }\n  .buttonWrapper .primary {\n    color: var(--text-color-inv);\n    background-color: var(--primary-color); }\n  .buttonWrapper .tertiary {\n    background-color: var(--secondary-color);\n    color: var(--text-color-inv); }\n  .buttonWrapper .secondary {\n    color: var(--primary-color);\n    background-color: var(--background-color);\n    border: 2px solid var(--primary-color); }\n\n.buttonSubWrapper {\n  border: none;\n  transition: background-color 0.3s;\n  padding: var(--gutter-m) var(--fontsize-l);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  cursor: unset;\n  font-weight: bold;\n  text-transform: uppercase;\n  font-size: var(--fontsize-m);\n  width: 100%;\n  white-space: nowrap; }\n\n.buttonIcon {\n  margin-right: var(--gutter-m); }\n");
var c = function (a) {
    var l = a.label,
      i = a.href,
      c = a.type,
      p = a.style,
      u = a.onClick,
      d = a.isBig,
      m = a.disabled,
      f = a.tabIndex,
      b = a.hover,
      y = a.hoverColor,
      v = a.isTargetBlank,
      g = a.icon,
      h = a.iconStyle,
      x = a.spinnerStyle,
      k = a.isLoading,
      S = a.children,
      w = a.buttonType,
      I = void 0 === w ? "button" : w,
      C = {
        type: c || "primary",
        style: p || {},
        hover: b || "transparent",
        hoverColor: y || "var(--wrapper-color-m)",
        href: i || "",
        label: l || "",
        isTargetBlank: v || "",
        tabIndex: f || 0,
        icon: g || "",
        iconStyle: h || {},
        isLoading: k || !1,
        spinnerStyle: x || {
          color: "white",
          size: 48
        },
        status: {
          width: d ? "100%" : "auto",
          cursor: m ? "not-allowed" : "pointer",
          userSelect: m ? "none" : "text",
          opacity: m ? ".5" : "1"
        }
      },
      E = n(""),
      W = e(E, 2),
      j = W[0],
      z = W[1],
      L = n(""),
      M = e(L, 2),
      B = M[0],
      N = M[1],
      T = o();
    t(function () {
      z(T.current.style.backgroundColor), N(T.current.style.color);
    }, []);
    var D = function (e) {
        m || (T.current.style.backgroundColor = "enter" === e ? C.hover : j, T.current.style.color = "enter" === e ? C.hoverColor : B);
      },
      O = function (e) {
        m ? e.preventDefault() : void 0 !== u && (i || e.preventDefault(), u(e));
      },
      _ = r.createElement(s, {
        config: C
      }, S);
    return r.createElement("span", {
      className: "buttonWrapper",
      style: C.status
    }, C.href ? r.createElement("a", {
      href: C.href,
      target: v,
      className: "buttonSubWrapper " + C.type,
      style: C.style,
      ref: T,
      tabIndex: C.tabIndex,
      onMouseOver: function () {
        return D("enter");
      },
      onMouseLeave: function () {
        return D("leave");
      },
      onClick: O
    }, _) : r.createElement("button", {
      type: I,
      className: "buttonSubWrapper " + C.type,
      style: C.style,
      ref: T,
      tabIndex: C.tabIndex,
      onMouseOver: function () {
        return D("enter");
      },
      onMouseLeave: function () {
        return D("leave");
      },
      onClick: O
    }, _));
  },
  s = function (e) {
    var n = e.config,
      t = e.children;
    return r.createElement(r.Fragment, null, n.isLoading && !t && r.createElement(l, {
      color: n.spinnerStyle.color,
      size: n.spinnerStyle.size
    }), n.isLoading && t && t, n.icon && r.createElement("div", {
      className: "buttonIcon",
      style: n.iconStyle
    }, r.createElement(a, {
      icon: n.icon
    })), n.label);
  };
export { c as default };