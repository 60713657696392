import React, { useState, useContext } from "react";

import MapContext from "common/contexts/map-context";
import MapService from "common/services/Map";
import TrackingService from "common/services/Tracking/Tracking";
import ExtractorService from "common/services/Extractor";
import BasicButton from "common/components/Widgets/BasicButton/BasicButton";
import IconsMapper from "common/components/IconsMapper/IconsMapper";

import { ROOT_IMG_URL } from "../../../../GlobalValues";
import { AsYouType } from "libphonenumber-js";
import "./PlaceInfos.css";

const PlaceInfosButtons = ({ merchant, isMobile }) => {
  const [map] = useContext(MapContext);
  const [displayPhone, setDisplayPhone] = useState("APPELEZ-NOUS");

  const getItinaryLink = async (e) => {
    e.preventDefault();
    if (map.scriptInited) {
      const { pagination } = await MapService.goToGeolocation(false);
      const infos = await MapService.getItinaryURL(merchant, {
        lat: pagination.from_lat,
        lng: pagination.from_lng,
      });
      TrackingService.trackClickToDirection(merchant.id, "real");
      if (!window.open(infos.url, infos.target)) {
        window.location = infos.url;
      }
    }
  };

  const seePhone = () => {
    if (!merchant.phone) {
      return;
    }
    const phoneNumber = new AsYouType().input(merchant.phone);
    TrackingService.trackClickToCall(merchant.id, "real");
    setDisplayPhone(phoneNumber);
  };

  return (
    <div className="flexGap placeInfosButtonsWrapper">
      {merchant.phone && (
        <BasicButton
          label={displayPhone}
          isBig={true}
          onClick={() => seePhone()}
          hover={"black"}
          href={"tel:" + merchant.phone}
          icon={"faPhone"}
          iconStyle={{
            fontSize: "var(--fontsize-m)",
          }}
          style={{
            fontSize: "var(--fontsize-xs)",
          }}
        />
      )}
      <BasicButton
        label="Calculer mon itinéraire"
        isBig={true}
        icon={"faMapLocationDot"}
        hover={"black"}
        onClick={(e) => getItinaryLink(e)}
        iconStyle={{
          fontSize: "var(--fontsize-m)",
        }}
        style={{
          fontSize: isMobile ? "45%" : "var(--fontsize-xs)",
        }}
      />
    </div>
  );
};

const PlaceInfos = ({ merchant }) => {
  const critizrField = merchant.extended_data.extended_string_data_8;
  let critizrEnable = false;

  if (critizrField) {
    critizrEnable = ExtractorService.extractBoolean(critizrField);
  }

  return (
    <>
      <section
        className="placeInfosWrapper"
        style={critizrEnable ? {} : { width: "60%" }}
      >
        <div className="flexGapColumn">
          <img
            src={ROOT_IMG_URL + "logo-vog.png"}
            alt="logo VOG"
            loading="lazy"
            width="73"
            height="69"
            style={{ height: "69px", width: "73px" }}
          />
          <div className="flexGap">
            {merchant.extended_data.extended_string_data_1 && (
              <a
                href={merchant.extended_data.extended_string_data_1}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className="placeInfosIcon">
                  <IconsMapper icon={"faFacebook"}></IconsMapper>
                </div>
              </a>
            )}
            {merchant.extended_data.extended_string_data_2 && (
              <a
                href={merchant.extended_data.extended_string_data_2}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className="placeInfosIcon">
                  <IconsMapper icon={"faInstagram"}></IconsMapper>
                </div>
              </a>
            )}
          </div>
        </div>
        <div>
          <h1>{merchant.name}</h1>
          <div className="flexGap placeInfosMiddle">
            <address
              className="placeInfosAddress"
              style={
                critizrEnable
                  ? {
                    maxWidth: "150px",
                  }
                  : {}
              }
            >
              {merchant.address && (
                <>
                  <p>{merchant.address.street}</p>
                  <div className="flexGap">
                    <p>{merchant.address.zip}</p>
                    <p>{merchant.address.city}</p>
                  </div>
                </>
              )}
            </address>
            {critizrEnable && (
              <div>
                <div style={{ height: "103px" }}>
                  <a
                    class="critizr-widget"
                    href={`https://critizr.com/widgets/vog-vog/${merchant.reference}/`}
                    data-type="store_button:rating_satisfaction"
                  >
                    Critizr: Envoyer mon avis
                  </a>
                </div>
                <div style={{
                  marginTop: "15px",
                }} >
                  <a class="goodays-widget" href={`https://app.goodays.co/widgets/vog-vog/${merchant.reference}/`} data-type="store_button:fact" data-params={`{"on_click":{"type":"cz:open:send_feedback:${merchant.reference}"},"cta_text":"Envoyer une remarque"}`}>Goodays: Envoyer mon avis</a>
                </div>
              </div>
            )}
          </div>

          <div className="desktop-only">
            <PlaceInfosButtons merchant={merchant} isMobile={false} />
          </div>
        </div>
      </section>
      <div className="placeInfosButtonsMobile">
        <PlaceInfosButtons merchant={merchant} isMobile={true} />
      </div>
    </>
  );
};

export default PlaceInfos;
