import { _ as e } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import t, { useContext as s } from "react";
import r from "../../contexts/state-context.js";
import i from "../Widgets/RichFieldCustomHTML/RichFieldCustomHTML.js";
import o from "../Widgets/RichFieldBulletPointList/RichFieldBulletPointList.js";
import "../../RichFieldBulletPointImageList-0d3ffab7.js";
import c from "../Widgets/RichFieldImageList/RichFieldImageList.js";
import n from "../Widgets/RichFieldNewsfeed/RichFieldNewsfeed.js";
import a from "../Widgets/RichFieldBannerImage/RichFieldBannerImage.js";
import "../../style-inject.es-1f59c1d0.js";
import "../IconsMapper/IconsMapper.js";
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
import "../../services/Images.js";
import "../../services/Tracking/Tracking.js";
import "../../services/Tracking/FacebookPixel.js";
import "../../services/Tracking/Pl.js";
import "../../services/ApiRequests.js";
import "axios";
import "../../services/Tracking/GoogleAnalitycs.js";
import "../../services/Utils.js";
import "../../services/Tracking/GoogleTagManager.js";
import "../../services/Url.js";
import "../Widgets/BasicButton/BasicButton.js";
import "../Widgets/Spinner/Spinner.js";
var l = function (l) {
  var m,
    p = l.groupId,
    d = l.style,
    u = void 0 === d ? {} : d,
    f = l.classes,
    g = void 0 === f ? {} : f,
    E = l.tracking,
    I = void 0 === E ? {} : E,
    T = s(r),
    L = e(T, 1)[0];
  m = p ? L.lp.rich_fields.filter(function (e) {
    return e.group === p;
  }) : L.lp.rich_fields, function () {
    try {
      m = m.sort(function (e, t) {
        return parseInt(e.order) < parseInt(t.order) ? -1 : 1;
      });
    } catch (e) {
      console.log(e);
    }
  }();
  var j = function (e) {
    var s = e.section;
    switch (s.fields = s.fields.sort(function (e, t) {
      return parseInt(e.order) < parseInt(t.order) ? -1 : 1;
    }), s.view_kind) {
      case "CUSTOM_HTML":
        return t.createElement(i, {
          section: s,
          style: u.CUSTOM_HTML,
          classes: g.CUSTOM_HTML
        });
      case "BULLET_POINT_LIST":
        return t.createElement(o, {
          section: s,
          style: u.BULLET_POINT_LIST,
          classes: g.BULLET_POINT_LIST
        });
      case "IMAGE_LIST":
        return t.createElement(c, {
          section: s,
          style: u.IMAGE_LIST,
          classes: g.IMAGE_LIST,
          tracking: I.IMAGE_LIST
        });
      case "NEWSFEED":
        return t.createElement(n, {
          section: s,
          style: u.NEWSFEED,
          classes: g.NEWSFEED
        });
      case "IMAGE_BANNER":
        return t.createElement(a, {
          section: s,
          style: u.IMAGE_BANNER,
          classes: g.IMAGE_BANNER,
          tracking: I.IMAGE_BANNER
        });
      default:
        return t.createElement(t.Fragment, null);
    }
  };
  return m.map(function (e, s) {
    return t.createElement(j, {
      section: e,
      key: s
    });
  });
};
export { l as default };