import { c as t, d as e, b as i } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
var n = new (function () {
  function n() {
    e(this, n), i(this, "script", {
      _trigger: function () {
        console.log("googleTagManager is loading");
      },
      _key: "",
      _inited: !1
    });
  }
  return t(n, [{
    key: "trigger",
    set: function (t) {
      this.script._trigger = t;
    }
  }, {
    key: "key",
    get: function () {
      return this.script._key;
    },
    set: function (t) {
      this.script._key = t;
    }
  }, {
    key: "inited",
    get: function () {
      return this.script._inited;
    },
    set: function (t) {
      this.script._inited = t;
    }
  }, {
    key: "pageView",
    value: function (t) {}
  }, {
    key: "trackEvent",
    value: function (t, e) {
      var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
        n = i.trackingEventName,
        r = i.eventLabel;
      this.script._trigger("event", n, {
        event_label: r
      });
    }
  }]), n;
}())();
export { n as default };