import e from "react";
import { s as r } from "../../../style-inject.es-1f59c1d0.js";
import t from "../../../services/Tracking/Tracking.js";
import a from "../../../services/Images.js";
import i from "../../../services/Url.js";
import "../../../_rollupPluginBabelHelpers-f0c52a37.js";
import "../../../services/Tracking/FacebookPixel.js";
import "../../../services/Tracking/Pl.js";
import "../../../services/ApiRequests.js";
import "axios";
import "../../../services/Tracking/GoogleAnalitycs.js";
import "../../../services/Utils.js";
import "../../../services/Tracking/GoogleTagManager.js";
r(".richFieldBannerImageWrapper {\n  margin-bottom: var(--gutter-m); }\n\n.richFieldBannerImageWrapper img {\n  display: inline-block;\n  width: 100%;\n  margin-bottom: var(--gutter-m); }\n");
var s = function (r) {
  var s = r.section,
    n = r.style,
    m = void 0 === n ? {} : n,
    c = r.tracking,
    l = {
      img: m.img || {},
      wrapper: m.wrapper || {},
      srcSet: m.srcSet || "small"
    },
    o = function () {
      if (c) {
        var e = c.merchant,
          r = c.brand,
          a = c.trackerName;
        t.trackEvent(e, r, {
          trackingEventName: a
        });
      }
    };
  return s = a.srcSetRichFields(l.srcSet, s), e.createElement(e.Fragment, null, e.createElement("div", {
    className: "richFieldBannerImageWrapper",
    style: l.wrapper
  }, s.fields.map(function (r, t) {
    return r.cta_target = i.modifyHTTP(r.cta_target), r.image = i.modifyHTTP(r.image), e.createElement(e.Fragment, null, r.cta_target ? e.createElement("a", {
      href: r.cta_target,
      target: "_blank",
      style: {
        cursor: "pointer"
      },
      key: t,
      onClick: o
    }, e.createElement("img", {
      key: t,
      loading: "lazy",
      src: r.image,
      style: l.img
    })) : e.createElement("img", {
      loading: "lazy",
      src: r.image,
      style: l.img
    }));
  })));
};
export { s as default };