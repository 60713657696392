import { _ as e } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import t, { useContext as a, useState as r, useEffect as n } from "react";
import i from "../../contexts/state-context.js";
import l from "../../services/Dates.js";
import { s as o } from "../../style-inject.es-1f59c1d0.js";
o(".hourdisplayerWrapper {\n  width: 100%; }\n  .hourdisplayerWrapper .hourWrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    color: black; }\n  .hourdisplayerWrapper .dayWrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: var(--gutter-m); }\n  .hourdisplayerWrapper .actualDay {\n    font-weight: bold; }\n  .hourdisplayerWrapper .additionalDayDescription {\n    color: #AAA; }\n");
var s = function (o) {
  o.type;
  var s = o.style,
    d = void 0 === s ? {} : s,
    c = o.children,
    m = a(i),
    p = e(m, 1)[0],
    y = r(),
    u = e(y, 2),
    f = u[0],
    g = u[1],
    h = r(p.merchant.workingTime.workingDaysPerWeek),
    E = e(h, 2),
    D = E[0],
    v = E[1],
    W = {
      style: {
        day: d.day || {},
        hour: d.hour || {},
        additionalDay: d.additionalDay || {},
        additionalHour: d.additionalHour || {},
        additionalDescription: d.additionalDescription || {}
      }
    },
    k = p.merchant.workingTime.additionalDays,
    b = !1;
  return p.lp && (b = p.lp.settings.features.display_opening_hours), n(function () {
    var e, t;
    e = p.merchant.workingTime.workingDaysPerWeek, t = l.getCleanWorkingDays(e), v(t), function () {
      var e = l.getActualDayToString();
      g(e);
    }();
  }, []), t.createElement(t.Fragment, null, c ? t.createElement(t.Fragment, null, t.Children.map(c, function (e, a) {
    t.Fragment;
  })) : t.createElement(t.Fragment, null, b && t.createElement("section", {
    className: "hourdisplayerWrapper"
  }, Object.entries(D).map(function (a) {
    var r = e(a, 2),
      n = r[0],
      i = r[1];
    return t.createElement("div", {
      className: n === f ? "dayWrapper actualDay" : "dayWrapper",
      key: n
    }, t.createElement("p", {
      style: W.style.day
    }, n), 0 === i.length ? t.createElement("div", {
      className: "hourWrapper"
    }, t.createElement("p", {
      style: W.style.day
    }, "Fermé")) : t.createElement("div", {
      className: "hourWrapper"
    }, i.map(function (e) {
      return t.createElement("p", {
        key: e.id,
        style: W.style.hour
      }, t.createElement("time", {
        dateTime: e.begin
      }, e.begin), "-", t.createElement("time", {
        dateTime: e.end
      }, e.end));
    })));
  }), Object.entries(k).filter(function (t) {
    var a = e(t, 2);
    a[0];
    var r = a[1];
    return l.filterAdditionalDay(r);
  }).sort(function (e, t) {
    var a = e[1].day.date,
      r = t[1].day.date;
    return new Date(a) - new Date(r);
  }).map(function (a) {
    var r = e(a, 2),
      n = r[0],
      i = r[1];
    return t.createElement("div", {
      className: "dayWrapper",
      key: n
    }, t.createElement("p", null, t.createElement("span", {
      style: W.style.additionalDay
    }, new Date(i.day.date).toLocaleDateString("fr-fr")), "" !== i.day.desc && t.createElement("span", null, t.createElement("br", null), t.createElement("div", {
      className: "additionalDayDescription",
      style: W.style.additionalDescription
    }, i.day.desc))), t.createElement("p", null, 0 === i.hours.length ? t.createElement("div", {
      className: "hourWrapper"
    }, t.createElement("p", {
      style: W.style.additionalHour
    }, "Fermé")) : t.createElement("div", {
      className: "hourWrapper",
      style: W.style.additionalHour
    }, i.hours.map(function (e) {
      return t.createElement("p", {
        key: e.id
      }, t.createElement("time", {
        dateTime: e.begin
      }, l.formatHour(e.begin)), "-", " ", t.createElement("time", {
        dateTime: e.end
      }, l.formatHour(e.end)));
    }))));
  }))));
};
export { s as default };