import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as t, b as r, e as n } from "../_rollupPluginBabelHelpers-f0c52a37.js";
import a from "axios";
var o = {
    validateStatus: function (e) {
      return !0;
    }
  },
  i = function (e) {
    var t = "";
    for (var r in e) e[r] && (t += "&" + r + "=" + e[r]);
    return t;
  },
  u = function () {
    function r() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : new c(),
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : new s();
      t(this, r), this.geoapify = e, this.eventCampaign = n;
    }
    var o, i, u, p, d;
    return e(r, [{
      key: "getBrands",
      value: (d = n(_regeneratorRuntime.mark(function e(t, r) {
        var n, o, i;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n = {
                number_of: (null == t ? void 0 : t.number_of) || void 0,
                skip: (null == t ? void 0 : t.skip) || void 0,
                order_by: (null == t ? void 0 : t.order_by) || void 0,
                order_dir: (null == t ? void 0 : t.order_dir) || void 0,
                from_lat: (null == t ? void 0 : t.from_lat) || void 0,
                from_lng: (null == t ? void 0 : t.from_lng) || void 0
              }, o = window.location.origin + "/endpoints/v1/storelocator/" + r.brand.id + "?startOn=" + n.skip + "&numberOf=" + n.number_of + "&orderBy=" + n.order_by + "&orderDir=" + n.order_dir + "&fromLat=" + n.from_lat + "&fromLong=" + n.from_lng + "&followStorelocConfig=true", r.brand.segmentId && (o += "&forSegment=".concat(r.brand.segmentId)), e.next = 5, a.get(o);
            case 5:
              return i = e.sent, e.abrupt("return", i.data);
            case 7:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e, t) {
        return d.apply(this, arguments);
      })
    }, {
      key: "subscribeForNewsletter",
      value: (p = n(_regeneratorRuntime.mark(function e(t, r, n) {
        var o, i, u;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return o = window.location.origin + "/endpoints/v1/storelocator/" + n + "/place/addabomail", i = {
                email: t,
                place_id: r
              }, e.next = 4, a.post(o, i);
            case 4:
              return u = e.sent, e.abrupt("return", u.data);
            case 6:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e, t, r) {
        return p.apply(this, arguments);
      })
    }, {
      key: "getStore",
      value: (u = n(_regeneratorRuntime.mark(function e(t, r) {
        var n, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n = window.location.origin + "/endpoints/v1/storelocator/" + t + "/localpage/" + r, e.next = 3, a.get(n);
            case 3:
              return o = e.sent, e.abrupt("return", o.data);
            case 5:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e, t) {
        return u.apply(this, arguments);
      })
    }, {
      key: "PlTracking",
      value: (i = n(_regeneratorRuntime.mark(function e(t, r, n) {
        var o,
          i,
          u,
          s = arguments;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return o = s.length > 3 && void 0 !== s[3] ? s[3] : "post", i = n ? window.location.origin + "/ajax/store-locator/" + r + "/" + n : window.location.origin + "/ajax/landingpage/event", e.next = 4, a[o](i, t);
            case 4:
              return u = e.sent, e.abrupt("return", u.data);
            case 6:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e, t, r) {
        return i.apply(this, arguments);
      })
    }, {
      key: "getCampaign",
      value: (o = n(_regeneratorRuntime.mark(function e(t) {
        var r, n;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return r = "/endpoints/v1/campaigns/" + t, e.next = 3, a.get(r);
            case 3:
              return n = e.sent, e.abrupt("return", n.data);
            case 5:
            case "end":
              return e.stop();
          }
        }, e);
      })), function (e) {
        return o.apply(this, arguments);
      })
    }]), r;
  }(),
  s = function () {
    function u() {
      t(this, u), r(this, "endpoint", "/endpoints/v2/offers");
    }
    var s, c;
    return e(u, [{
      key: "obtainTicket",
      value: (c = n(_regeneratorRuntime.mark(function e(t, r) {
        var n,
          u,
          s,
          c,
          p = arguments;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n = p.length > 2 && void 0 !== p[2] ? p[2] : {}, u = i(n), s = "".concat(this.endpoint, "/").concat(t, "/coupons?").concat(u.substring(1)), e.next = 5, a.post(s, r, o);
            case 5:
              return c = e.sent, e.abrupt("return", this.handleResponse(c));
            case 7:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return c.apply(this, arguments);
      })
    }, {
      key: "notifyTicketOwnerForNotRedeemedTickets",
      value: (s = n(_regeneratorRuntime.mark(function e(t, r) {
        var n, i;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return n = "".concat(this.endpoint, "/").concat(t, "/coupons/_notify"), e.next = 3, a.post(n, r, o);
            case 3:
              return i = e.sent, e.abrupt("return", this.handleResponse(i));
            case 5:
            case "end":
              return e.stop();
          }
        }, e, this);
      })), function (e, t) {
        return s.apply(this, arguments);
      })
    }, {
      key: "handleResponse",
      value: function (e) {
        if (e.data.success) return e.data.data;
        var t = new Error(e.data.message);
        throw t.status = e.status, t;
      }
    }]), u;
  }(),
  c = function () {
    function o() {
      t(this, o), r(this, "endpoint", "/map/geoapify");
    }
    var i, u, s;
    return e(o, [{
      key: "getPlacePredictions",
      value: (s = n(_regeneratorRuntime.mark(function e(t) {
        var r, n;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.prev = 0, r = "".concat(this.endpoint, "/autocomplete/").concat(encodeURIComponent(t)), e.next = 4, a.get(r);
            case 4:
              return n = e.sent, e.abrupt("return", n.data.data);
            case 8:
              return e.prev = 8, e.t0 = e.catch(0), console.log(e.t0), e.abrupt("return", []);
            case 12:
            case "end":
              return e.stop();
          }
        }, e, this, [[0, 8]]);
      })), function (e) {
        return s.apply(this, arguments);
      })
    }, {
      key: "reverseGeocoding",
      value: (u = n(_regeneratorRuntime.mark(function e(t, r) {
        var n, o;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.prev = 0, n = "".concat(this.endpoint, "/reverse/").concat(t, "/").concat(r), e.next = 4, a.get(n);
            case 4:
              return o = e.sent, e.abrupt("return", o.data.data);
            case 8:
              return e.prev = 8, e.t0 = e.catch(0), console.log(e.t0), e.abrupt("return", []);
            case 12:
            case "end":
              return e.stop();
          }
        }, e, this, [[0, 8]]);
      })), function (e, t) {
        return u.apply(this, arguments);
      })
    }, {
      key: "getPlaceByName",
      value: (i = n(_regeneratorRuntime.mark(function e(t) {
        var r, n;
        return _regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.prev = 0, r = "".concat(this.endpoint, "/search/").concat(encodeURIComponent(t)), e.next = 4, a.get(r);
            case 4:
              return n = e.sent, e.abrupt("return", n.data.data);
            case 8:
              return e.prev = 8, e.t0 = e.catch(0), console.log(e.t0), e.abrupt("return", []);
            case 12:
            case "end":
              return e.stop();
          }
        }, e, this, [[0, 8]]);
      })), function (e) {
        return i.apply(this, arguments);
      })
    }]), o;
  }(),
  p = new u();
export { p as default };