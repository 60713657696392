import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { _ as e, e as a } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import o, { useState as r, useEffect as t } from "react";
import { FontAwesomeIcon as n } from "@fortawesome/react-fontawesome";
import { faUser as f, faUsers as c, faBan as s, faCoffee as i, faLocationDot as m, faMapLocationDot as u, faPhone as p, faChevronDown as l, faCalendarDays as h, faScissors as v, faNoteSticky as w, faArrowLeft as d } from "@fortawesome/free-solid-svg-icons";
var g = {
    prefix: "fab",
    iconName: "facebook",
    icon: [512, 512, [62e3], "f09a", "M504 256C504 119 393 8 256 8S8 119 8 256c0 123.8 90.69 226.4 209.3 245V327.7h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.3 482.4 504 379.8 504 256z"]
  },
  z = {
    prefix: "fab",
    iconName: "instagram",
    icon: [448, 512, [], "f16d", "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"]
  },
  b = function (b) {
    var y = b.icon,
      C = b.style,
      k = void 0 === C ? {} : C,
      x = r(""),
      D = e(x, 2),
      M = D[0],
      S = D[1],
      V = {
        faUser: f,
        faUsers: c,
        faBan: s,
        faCoffee: i,
        faLocationDot: m,
        faMapLocationDot: u,
        faPhone: p,
        faFacebook: g,
        faInstagram: z,
        faChevronDown: l,
        faCalendarDays: h,
        faScissors: v,
        faNoteSticky: w,
        faArrowLeft: d
      },
      L = function () {
        var e = a(_regeneratorRuntime.mark(function e(a) {
          return _regeneratorRuntime.wrap(function (e) {
            for (;;) switch (e.prev = e.next) {
              case 0:
                S(V[a]);
              case 2:
              case "end":
                return e.stop();
            }
          }, e);
        }));
        return function (a) {
          return e.apply(this, arguments);
        };
      }();
    return t(function () {
      L(y);
    }, [y]), o.createElement(n, {
      icon: M,
      style: k
    });
  };
export { b as default };