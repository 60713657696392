const IMG_SIZE = {
  IMAGE_LIST: {
    width: "264px",
    height: "185px",
  },
  NEWSFEED: {
    width: "200px",
    height: "200px",
  },
  IMAGE_BANNER: {
    width: "var(--aside-width)",
    height: "var(--aside-width)",
  },
  NEWSFEED_ASIDE: {
    width: "100%",
    height: "218px",
  },
};

export const RICHFIELDS_CONFIG_MOBILE = {
  /////////////////////////////////
  //                             //
  //         MAIN_A CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_A: {
    CUSTOM_HTML: {
      wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
      },
      content: {
        backgroundColor: "",
      },
      title: {
        marginLeft: "0",
        marginBottom: "var(--gutter-s)",
        alignSelf: "flex-start",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //         MAIN_B CONFIG       //
  //                             //
  /////////////////////////////////
  MAIN_B_CLASSES: {
    NEWSFEED: {
      fullContainer: "mobile-only",
    },
    IMAGE_LIST: {
      wrapper: "mobile-only",
    },
  },

  MAIN_B: {
    IMAGE_LIST: {
      srcSet: "xsmall",
      wrapper: {
        display: "none",
      },
    },
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      title: {
        display: "none",
      },
      fullWrapper: {
        justifyContent: "normal",
        gap: "var(--gutter-l)",
      },
      newsWrapper: {
        width: "47%",
        marginBottom: 0,
      },
      imgWrapper: {
        width: "100%",
        height: "180px",
        marginBottom: 0,
      },
      fullContainer: {
        padding: "0 var(--gutter-xl)",
        marginTop: "-50px",
        marginBottom: 0,
        backgroundColor: "inherit",
        width: "90%",
      },
      newsTextWrapper: {
        display: "none",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN C CONFIG        //
  //                             //
  /////////////////////////////////
  MAIN_C_CLASSES: {
    NEWSFEED: {
      fullContainer: "mobile-only",
    },
    IMAGE_LIST: {
      wrapper: "mobile-only",
    },
  },
  MAIN_C: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        marginBottom: 0,
      },
      newsWrapper: {
        width: "100%",
        flexDirection: "column",
      },
      title: {
        backgroundColor: "var(--primary-color)",
        color: "white",
        width: "calc(100% - (var(--gutter-m) * 2 ) )",
        padding: "var(--gutter-m)",
      },
      imgWrapper: {
        width: "100%",
        height: "var(--mobile-cube-height)",
        marginBottom: 0,
      },
      fullContainer: {
        width: "90%",
        padding: 0,
      },
      paragraph: {
        display: "none",
      },
      buttonWrapper: {
        display: "none",
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      wrapper: {
        display: "none",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //            ASIDE            //
  //                             //
  /////////////////////////////////
  ASIDE_A: {
    IMAGE_BANNER: {
      srcSet: "xsmall",
      wrapper: {
        border: "var(--gutter-s) solid var(--tertiary-color)",
        width: "100%",
        height: "var(--mobile-cube-height)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        ASIDE B              //
  //                             //
  /////////////////////////////////
  ASIDE_B_CLASSES: {
    NEWSFEED: {
      fullContainer: "mobile-only",
    },
  },
  ASIDE_B: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      fullContainer: {
        padding: 0,
        width: "90%",
      },
      fullWrapper: {
        width: "100%",
        gap: "20px",
      },
      imgWrapper: {
        width: "50%",
        height: "var(--mobile-cube-height)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        margin: 0,
        boxSizing: "border-box",
        border: "5px solid black",
      },
      newsWrapper: {
        width: "100%",
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: "var(--gutter-l)",
      },
      newsTextWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        width: "50%",
        height: "var(--mobile-cube-height)",
        flexDirection: "column",
      },
      title: { color: "white" },
      desc: {
        color: "white",
        textAlign: "center",
      },
      buttonWrapper: {
        display: "none",
      },
      paragraph: {
        textAlign: "center",
      },
    },
  },
};

export const RICHFIELDS_CONFIG_DESKTOP = {
  /////////////////////////////////
  //                             //
  //         MAIN_A CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_A: {
    CUSTOM_HTML: {
      content: {
        backgroundColor: "var(--tertiary-color)",
        padding: "var(--gutter-xl)",
      },
      title: {
        marginLeft: "var(--gutter-xl)",
        marginBottom: "calc(var(--gutter-m) * -1)",
      },
    },
  },

  /////////////////////////////////
  //                             //
  //         MAIN_B CONFIG       //
  //                             //
  /////////////////////////////////

  MAIN_B_CLASSES: {
    IMAGE_LIST: {
      wrapper: "desktop-only",
    },
    NEWSFEED: {
      fullContainer: "desktop-only",
    },
  },

  MAIN_B: {
    IMAGE_LIST: {
      srcSet: "xsmall",
      imgContainer: {
        display: "flex",
        padding: " 0 var(--gutter-xl)",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
      },
      imgWrapper: {
        border: "solid 4px var(--borderPrimaryColor)",
        width: IMG_SIZE.IMAGE_LIST.width,
        height: IMG_SIZE.IMAGE_LIST.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      title: {
        marginLeft: "var(--gutter-xl)",
      },
      wrapper: {
        padding: 0,
      },
    },
    NEWSFEED: {
      fullWrapper: {
        display: "flex"
      },
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      title: {
        display: "none",
      },
      imgWrapper: {
        width: IMG_SIZE.NEWSFEED.width,
        height: IMG_SIZE.NEWSFEED.height,
      },
      fullContainer: {
        padding: "0 var(--gutter-xl)",
        marginTop: "-50px",
        marginBottom: 0,
        backgroundColor: "inherit",
      },
      newsWrapper: {
        width: IMG_SIZE.NEWSFEED.width,
      },
      desc: {
        color: "black",
      },
      paragraph: {
        height: "auto",
        textAlign: "center",
        color: "var(--borderPrimaryColor)",
        webkitLineClamp: "inherit",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        MAIN C CONFIG        //
  //                             //
  /////////////////////////////////
  MAIN_C: {
    srcSet: "xsmall",
    NEWSFEED: {
      headerTitle: {
        marginBottom: 0,
      },
      newsWrapper: {
        width: IMG_SIZE.IMAGE_LIST.width,
      },
      fullWrapper: {
        backgroundColor: "var(--tertiary-color)",
        padding: "var(--gutter-m)",
      },
      imgWrapper: {
        width: IMG_SIZE.IMAGE_LIST.width,
        height: IMG_SIZE.IMAGE_LIST.height,
      },
      fullContainer: {
        padding: "0 var(--gutter-xl)",
        marginBottom: 0,
      },
      buttonWrapper: {
        margin: 0,
      },
      button: {
        hoverColor: "var(--primary-color)",
        style: {
          fontSize: "var(--fontsize-s)",
          backgroundColor: "transparent",
          marginTop: 0,
          padding: 0,
          color:"black"
        },
      },
    },
    IMAGE_LIST: {
      srcSet: "xsmall",
      imgContainer: {
        padding: "0 var(--gutter-xl)",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "var(--gutter-m)",
      },
      imgWrapper: {
        border: "solid 4px var(--borderPrimaryColor)",
        width: IMG_SIZE.IMAGE_LIST.width,
        height: IMG_SIZE.IMAGE_LIST.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      title: {
        marginLeft: "var(--gutter-xl)",
      },
      wrapper: {
        padding: 0,
      },
    },
  },
  /////////////////////////////////
  //                             //
  //            ASIDE            //
  //                             //
  /////////////////////////////////
  ASIDE_A: {
    IMAGE_BANNER: {
      srcSet: "xsmall",
      wrapper: {
        border: "var(--gutter-m) solid var(--tertiary-color)",
        width: "calc(  var(--aside-width) - (var(--gutter-m)*2))",
        height: IMG_SIZE.IMAGE_BANNER.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      img: {
        width: "100%",
      },
    },
  },
  /////////////////////////////////
  //                             //
  //        ASIDE B              //
  //                             //
  /////////////////////////////////
  ASIDE_B: {
    NEWSFEED: {
      srcSet: "xsmall",
      headerTitle: {
        display: "none",
      },
      fullContainer: {
        padding: 0,
        backgroundColor: "black",
        width: "var(--aside-width)",
        marginBottom: 0,
      },
      fullWrapper: {
        width: "100%",
      },
      imgWrapper: {
        width: IMG_SIZE.NEWSFEED_ASIDE.width,
        height: IMG_SIZE.NEWSFEED_ASIDE.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        marginRight: 0,
      },
      newsWrapper: {
        width: "100%",
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        padding: "var(--gutter-m)",
      },
      title: { color: "white" },
      desc: {
        color: "white",
        textAlign: "center",
      },
      buttonWrapper: {
        display: "none",
      },
    },
  },
};
