import _regeneratorRuntime from "/usr/local/share/.config/yarn/global/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator";
import { c as e, d as t, b as r, e as n } from "../../_rollupPluginBabelHelpers-f0c52a37.js";
import i from "./FacebookPixel.js";
import a from "./Pl.js";
import s from "./GoogleAnalitycs.js";
import o from "../Utils.js";
import c from "./GoogleTagManager.js";
import "../ApiRequests.js";
import "axios";
var l = new (function () {
  function l() {
    t(this, l), r(this, "brand", {}), r(this, "scriptsList", {
      facebookPixel: i,
      pl: a,
      googleAnalitycs: s,
      googleTagManager: c
    });
  }
  var u, g, v, h, p, k, f, d;
  return e(l, [{
    key: "getServiceKey",
    value: function (e) {
      return this.scriptsList[e].key;
    }
  }, {
    key: "getInitedStatus",
    value: function (e) {
      return this.scriptsList[e].inited;
    }
  }, {
    key: "setServiceKey",
    value: function (e, t) {
      this.scriptsList[e].key = t;
    }
  }, {
    key: "setTrigger",
    value: function (e, t) {
      this.scriptsList[e].trigger = t, this.scriptsList[e].inited = !0;
    }
  }, {
    key: "init",
    value: function (e, t) {
      this.brand = e.brand, this.handleBackOfficeKeys(e), this.handleCookies(t);
    }
  }, {
    key: "handleCookies",
    value: function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        t = e._fbc,
        r = e._fbp;
      this.scriptsList.facebookPixel.cookies = {
        _fbc: t,
        _fbp: r
      };
    }
  }, {
    key: "handleBackOfficeKeys",
    value: function (e) {
      this.setFacebookPixel(e), this.setGoogleAnalitycs(e), this.setGoogleTagManager(e);
    }
  }, {
    key: "setFacebookPixel",
    value: function (e) {
      e.brand.segment.facebookPixel && this.setServiceKey("facebookPixel", e.brand.segment.facebookPixel);
    }
  }, {
    key: "setGoogleAnalitycs",
    value: function (e) {
      if (e.sl) {
        var t = e.sl.settings.apikeys.ganalytics,
          r = e.brand.segment.googleAnalyticsId;
        if (r) return void this.setServiceKey("googleAnalitycs", r);
        if (t) return void this.setServiceKey("googleAnalitycs", t);
      }
      if (e.lp) {
        var n = e.lp.settings.apikeys.ganalytics,
          i = e.brand.segment.googleAnalyticsId;
        if (i) return void this.setServiceKey("googleAnalitycs", i);
        if (n) return void this.setServiceKey("googleAnalitycs", n);
      }
    }
  }, {
    key: "setGoogleTagManager",
    value: function (e) {
      e.sl && this.setServiceKey("googleTagManager", e.sl.settings.apikeys.gtagmanager), e.lp && this.setServiceKey("googleTagManager", e.lp.settings.apikeys.gtagmanager);
    }
  }, {
    key: "trackSearchedPlace",
    value: (d = n(_regeneratorRuntime.mark(function e(t, r) {
      var n,
        i,
        s,
        c = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return n = c.length > 2 && void 0 !== c[2] ? c[2] : "Placeloop - Search", i = c.length > 3 && void 0 !== c[3] ? c[3] : "SL", s = o.uniqueId(), e.next = 5, this.trackEventWithExternalServices(void 0, this.brand, {
              trackingEventName: n,
              eventLabel: n,
              eventID: s,
              source: i
            });
          case 5:
            return e.next = 7, a.trackSearchedPlace(this.brand, t, r, s);
          case 7:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t) {
      return d.apply(this, arguments);
    })
  }, {
    key: "trackClickToCall",
    value: (f = n(_regeneratorRuntime.mark(function e(t, r) {
      var n,
        i,
        s,
        c = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return n = c.length > 2 && void 0 !== c[2] ? c[2] : "Placeloop - ClickToCall", i = c.length > 3 && void 0 !== c[3] ? c[3] : "LP", s = o.uniqueId(), e.next = 5, this.trackEventWithExternalServices(t, this.brand, {
              trackingEventName: n,
              eventLabel: n,
              eventID: s,
              source: i
            });
          case 5:
            return e.next = 7, a.trackClickToCall(this.brand, t, r, s);
          case 7:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t) {
      return f.apply(this, arguments);
    })
  }, {
    key: "trackClickToDirection",
    value: (k = n(_regeneratorRuntime.mark(function e(t, r) {
      var n,
        i,
        s,
        c = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return n = c.length > 2 && void 0 !== c[2] ? c[2] : "Placeloop - GetDirections", i = c.length > 3 && void 0 !== c[3] ? c[3] : "LP", s = o.uniqueId(), e.next = 5, this.trackEventWithExternalServices(t, this.brand, {
              trackingEventName: n,
              eventLabel: n,
              eventID: s,
              source: i
            });
          case 5:
            return e.next = 7, a.trackClickToDirection(this.brand, t, r, s);
          case 7:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t) {
      return k.apply(this, arguments);
    })
  }, {
    key: "trackGeolocatedPlace",
    value: (p = n(_regeneratorRuntime.mark(function e(t, r, n) {
      var i,
        s,
        c,
        l = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return i = l.length > 3 && void 0 !== l[3] ? l[3] : "Placeloop - Geolocation", s = l.length > 4 && void 0 !== l[4] ? l[4] : "SL", c = o.uniqueId(), e.next = 5, this.trackEventWithExternalServices(void 0, this.brand, {
              trackingEventName: i,
              eventLabel: i,
              eventID: c,
              source: s
            });
          case 5:
            return e.next = 7, a.trackGeolocatedPlace(this.brand, t, r, n, c);
          case 7:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t, r) {
      return p.apply(this, arguments);
    })
  }, {
    key: "pageView",
    value: (h = n(_regeneratorRuntime.mark(function e(t, r) {
      var n,
        i,
        s = arguments;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return n = s.length > 2 && void 0 !== s[2] ? s[2] : "Placeloop - PageView", i = o.uniqueId(), e.next = 4, this.trackPageViewWithExternalServices(n, i);
          case 4:
            return e.next = 6, a.pageView(this.brand, r, t, i);
          case 6:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t) {
      return h.apply(this, arguments);
    })
  }, {
    key: "trackPageViewWithExternalServices",
    value: (v = n(_regeneratorRuntime.mark(function e(t, r) {
      var n, i;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            for (n in this.scriptsList) (i = this.scriptsList[n]).key && i.inited && i.pageView(t, r);
          case 1:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t) {
      return v.apply(this, arguments);
    })
  }, {
    key: "trackEventWithExternalServices",
    value: (g = n(_regeneratorRuntime.mark(function e(t, r, n) {
      var i, a;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            for (i in this.scriptsList) (a = this.scriptsList[i]).key && a.inited && a.trackEvent(t, r, n);
          case 1:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t, r) {
      return g.apply(this, arguments);
    })
  }, {
    key: "trackEvent",
    value: (u = n(_regeneratorRuntime.mark(function e(t, r, n) {
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) switch (e.prev = e.next) {
          case 0:
            return e.next = 2, this.trackEventWithExternalServices(t, r, n);
          case 2:
            if (!n.trackingEventNamePL) {
              e.next = 5;
              break;
            }
            return e.next = 5, a.trackLPEvent(r, t.id, "real", n.trackingEventNamePL);
          case 5:
          case "end":
            return e.stop();
        }
      }, e, this);
    })), function (e, t, r) {
      return u.apply(this, arguments);
    })
  }]), l;
}())();
export { l as default };