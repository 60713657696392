import React, { createRef, useState, useEffect } from "react";

import HourDisplayer from "common/components/HourDisplayer/HourDisplayer";
import DatesService from "common/services/Dates";
import IconsMapper from "common/components/IconsMapper/IconsMapper";

import "./OpeningHours.css";

const OpeningHours = ({ merchant }) => {
  const [hoursDisplayed, setHoursDisplayed] = useState(false);
  const [todayClosingTime, setTodayClosingTime] = useState("");
  const hoursDetails = createRef();

  const storeIsOpen = DatesService.storeIsOpen(merchant.workingTime);

  const toggleHoursDisplay = () => {
    setHoursDisplayed(!hoursDisplayed);
  };

  useEffect(() => {
    const getTodayClosingTime = () => {
      if (!storeIsOpen) {
        return;
      }
      try {
        const actualDayToString = DatesService.getActualDayToStringKey();
        const todayArray =
          merchant.workingTime.workingDaysPerWeek[actualDayToString];
        const lastWorkingTime = todayArray[todayArray.length - 1]?.end;
        let result = "";
        if (lastWorkingTime) {
          result = lastWorkingTime.replace(":", "h");
        } else {
          const todayAdditionalDay = DatesService.getTodayAdditionalDay(
            merchant.workingTime.additionalDays
          );
          if (todayAdditionalDay) {
            const loastWorkingTimeAdditional =
              todayAdditionalDay.hours[todayAdditionalDay.hours.length - 1]
                ?.end;
            result = loastWorkingTimeAdditional.replace(":", "h");
          }
        }
        setTodayClosingTime(result);
      } catch (err) {
        console.log("DATE PARSE ERROR", err);
      }
    };
    getTodayClosingTime();
  }, [
    merchant.workingTime.workingDaysPerWeek,
    merchant.workingTime.additionalDays,
    storeIsOpen,
  ]);

  useEffect(() => {
    if (hoursDetails.current) {
      hoursDetails.current.style.display = hoursDisplayed ? "block" : "none";
    }
  }, [hoursDisplayed, hoursDetails]);

  return (
    <section
      className="openingHoursWrapper"
      onClick={() => toggleHoursDisplay()}
    >
      <h2>{storeIsOpen ? "Ouvert aujourd'hui" : 'Actuellement Fermé'} </h2>
      {storeIsOpen && <p>jusqu'à {todayClosingTime}</p>}
      <hr />
      <div>
        <div className="flexGap showHours">
          <p>
            <small>Consulter tous les horaires</small>
          </p>
          <div
            style={
              hoursDisplayed
                ? {
                    transition: '0.3s',
                  }
                : {
                    transform: 'rotate(90deg)',
                    transition: '0.3s',
                  }
            }
          >
            <IconsMapper icon={'faChevronDown'}></IconsMapper>
          </div>
        </div>
      </div>
      <div
        className="hourDisplayerWrapper"
        ref={hoursDetails}
        style={{ display: 'none' }}
      >
        <HourDisplayer></HourDisplayer>
      </div>
    </section>
  );
};

export default OpeningHours;
