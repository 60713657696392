import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import StateContext from 'common/contexts/state-context';
import useAutoUserGeolocation from 'common/customHooks/useAutoUserGeolocation';

import MerchantList from './MerchantList/MerchantList';
import HomeSearch from './HomeSearch/HomeSearch';

const StoreLocator = () => {
  const [state] = useContext(StateContext);
  const { slug } = useParams();

  const autoUserGeolocationURL = useAutoUserGeolocation();

  if (autoUserGeolocationURL) {
    window.location.href = autoUserGeolocationURL;
    return null;
  }

  return (
    <>
      {state.sl && (
        <>
          <HomeSearch withGoTo={!!slug}></HomeSearch>
          {!state.sl.home_search && <MerchantList></MerchantList>}
        </>
      )}
    </>
  );
};

export default StoreLocator;
